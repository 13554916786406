import Vue from 'vue'
import VueRouter from 'vue-router';
// 路由页面:这里不配置懒加载,因为网速慢的时候，会出现next路由延迟的BUG,虽然这里没有使用依赖，但实际已经提前加载好了对应的内容
import home from './views/home.vue';
import videos from './views/videos.vue';
import photos from './views/photos.vue';
import contact from './views/contact.vue';
import error from './views/404.vue';
Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('./views/home.vue'),
        },
        {
            path: '/videos',
            name: 'videos',
            component: () => import('./views/videos.vue'),
        },
        {
            path: '/photos',
            name: 'photos',
            component: () => import('./views/photos.vue'),
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('./views/contact.vue'),
        },
        {
            name: '404',
            path: '/404',
            component: () => import('./views/404.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/404'
        }
    ],
    scrollBehavior() {
        // 避免在路由历史跳转的时候，闪动滑动页面
        return { x: 0, y: 0 }
    }
});
// 为路由配置set_title方法：在页面更改的时候修改页面的标题
router.set_title = (to) => {
    document.title = `${to.name.toUpperCase()} - JIEJOE | 视觉设计者`;
}
export default router;

