<template>
    <div id="scrollbar" :style="{'--p':this.$parent.$parent.scroll_controler.progress}"></div>
</template>

<script>
export default {
    name: "scrollbar",
};
</script>

<style>
#scrollbar {
    position: fixed;
    left: 0;
    width: 0.3rem;
    height: 100%;
    z-index: 10000000;
    overflow: hidden;
}
#scrollbar::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--theme_green);
    border-radius: 10rem;
    transform-origin: top;
    transform: translateY(calc((1 - var(--p)) * -100%));
}
</style>
