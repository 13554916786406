<template>
    <div class="loading" v-show="if_visible">
        <div class="loading_greenblock"></div>
        <div class="loading_blackblock"></div>
        <div class="loading_animation">
            <div class="loading_animation_icon"></div>
            <p class="loading_animation_tip">LOADING</p>
        </div>
    </div>
</template>

<script>
import lottie from "lottie-web";
import gsap from "gsap";
export default {
    name: "loading",
    data() {
        return {
            // DOM元素
            animation: null,
            blackblock: null,
            greenblock: null,
            // lottie动画播放器
            animater_player: null,
            if_visible: true,
            // 转化器：用于GSAP控制loading进入，隐藏动画
            changer: null,
        };
    },
    methods: {
        init() {
            this.animation = document.querySelector(".loading_animation");
            this.blackblock = document.querySelector(".loading_blackblock");
            this.greenblock = document.querySelector(".loading_greenblock");
            this.create_animater();
        },
        // 创建lottie动画
        create_animater() {
            this.animater_player = lottie.loadAnimation({
                container: document.querySelector(".loading_animation_icon"),
                renderer: "svg",
                loop: false,
                autoplay: true,
                animationData: require("../assets/animations/loading.json"),
            });
            // 为loading动画监听帧，设置不同条件下的动画
            this.animater_player.addEventListener("enterFrame", () => {
                // 若不为第一次进入，且页面已经加载完毕：直接执行hidden，不全部加载完动画
                if (localStorage.getItem("if_firstin") && this.$parent.if_loadover)
                    return this.hidden();
                //动画完成过渡变化
                if (this.animater_player.currentFrame >= 181) {
                    // 页面是否加载完毕，否则再次播放动画
                    if (this.$parent.if_loadover) {
                        // 页面加载完成，需要等到动画全部播放完才进入页面,localStorage
                        if (this.animater_player.currentFrame > 260) {
                            this.hidden();
                            localStorage.setItem("if_firstin", true);
                        }
                    } else this.animater_player.goToAndPlay(0, true);
                }
            });
        },
        show(next, to) {
            this.$parent.scroll_controler.stop(); // 锁定滑动控制器：页面跳转的时候，不允许页面再滑动
            this.if_visible = true;
            this.animater_player.goToAndPlay(0, true);
            if (this.changer) this.changer.kill(); //如果处于GSAP动画播放中，则打断
            this.changer = gsap
                .timeline()
                .to(this.greenblock, {
                    x: 0,
                    "border-radius": 0,
                    duration: 1,
                    ease: "power4.out",
                })
                .to(
                    this.blackblock,
                    {
                        x: 0,
                        "border-radius": 0,
                        duration: 1,
                        delay: 0.3,
                        ease: "power4.out",
                        onComplete: () => {
                            window.scrollTo(0, 0); //将页面滑动到顶端，保证每次进入页面都是从顶部浏览，这里没用lenis是因为lenis已经被锁定了
                            next(); //延迟执行页面跳转
                            this.$router.set_title(to); //延迟更改页面标题
                            this.$parent.check_loading(); //重新检查页面加载状态
                            if (this.$parent.$refs.menu.if_visible)
                                this.$parent.$refs.menu.reload(); //如果menu可见：则重载menu样式
                        },
                    },
                    "<"
                )
                .to(
                    this.animation,
                    {
                        opacity: 1,
                        duration: 0.5,
                        ease: "power4.out",
                    },
                    "<"
                );
        },
        hidden() {
            this.animater_player.pause();
            if (this.changer) this.changer.kill(); //如果处于GSAP动画播放中，则打断
            this.changer = gsap
                .timeline()
                .to(this.animation, {
                    opacity: 0,
                    duration: 0.5,
                    ease: "power4.out",
                })
                .to(
                    this.blackblock,
                    {
                        x: "100%",
                        "border-radius": "3rem",
                        duration: 1,
                        ease: "power4.out",
                    },
                    "<0.2"
                )
                .to(
                    this.greenblock,
                    {
                        x: "100%",
                        "border-radius": "3rem",
                        duration: 1,
                        delay: 0.3,
                        ease: "power4.out",
                        onStart: () => {
                            this.$parent.scroll_controler.start(); // 解锁滑动
                            this.$parent.init_animation(); //触发当前页面的初始动画
                        },
                        onComplete: () => {
                            this.if_visible = false;
                        },
                    },
                    "<"
                );
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
.loading {
    --scale_nums: 0.6;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000000;
}
.loading_greenblock {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--theme_green) url(~@/assets/images/loading_block.svg) repeat;
    background-size: 30%;
}
.loading_blackblock {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--theme_black);
}
.loading_animation {
    flex-direction: column;
    align-items: center;
}
.loading_animation_icon {
    width: calc(25rem * var(--scale_nums));
    height: calc(25rem * var(--scale_nums));
}
.loading_animation_tip {
    font-family: eng;
    font-size: calc(1.5rem * var(--scale_nums));
    color: var(--theme_green);
    letter-spacing: calc(0.3rem * var(--scale_nums));
    margin-right: calc(-0.3rem * var(--scale_nums));
    transform: translateY(calc(-6rem * var(--scale_nums)));
}
@media screen and (max-aspect-ratio: 1.5/1) {
    .loading {
        --scale_nums: 1;
    }
}
@media screen and (max-aspect-ratio: 1/1) {
    .loading {
        --scale_nums: 0.8;
    }
}
</style>
