<template>
    <div id="videos">
        <Scrollbar />
        <svg id="videos_resources" style="display:none">
            <defs>
                <!-- 屏幕波动滤镜 -->
                <filter id="videos_resources_wave_screen">
                    <feTurbulence type="turbulence" baseFrequency="0 1" numOctaves="2" :seed="animation_controler.wave_seed" stitchTiles="stitch" />
                    <feDisplacementMap in="SourceGraphic" :scale="animation_controler.wave_scale" />
                </filter>
                <!-- 图片波动滤镜 -->
                <filter id="videos_resources_wave_image">
                    <feTurbulence type="turbulence" baseFrequency="0 1" numOctaves="2" :seed="image_wave.seed" stitchTiles="stitch" />
                    <feDisplacementMap in="SourceGraphic" :scale="image_wave.scale" />
                </filter>
                <!-- 红色滤镜 -->
                <filter id="videos_resources_fillcolor_red" x="0" y="0">
                    <feColorMatrix
                        type="matrix"
                        values="
                    0 0 0 0.9   0
                    0 0 0 0.1 0
                    0 0 0 0.1 0
                    0 0 0 1   0
                "
                    />
                </filter>
                <!-- 浅蓝色滤镜 -->
                <filter id="videos_resources_fillcolor_lightblue" x="0" y="0">
                    <feColorMatrix
                        type="matrix"
                        values="
                    0 0 0 0   0
                    0 0 0 0.9 0
                    0 0 0 0.9 0
                    0 0 0 1   0
                "
                    />
                </filter>
                <!-- 深蓝色滤镜 -->
                <filter id="videos_resources_fillcolor_deepblue" x="0" y="0">
                    <feColorMatrix
                        type="matrix"
                        values="
                    0 0 0 0   0
                    0 0 0 0.1 0
                    0 0 0 0.9 0
                    0 0 0 0.9   0
                "
                    />
                </filter>
            </defs>
        </svg>
        <div class="videos_header">
            <div class="videos_header_title" :class="{'image_wave':image_wave.if_open}">
                <p>v</p>
                <p>i</p>
                <p>d</p>
                <p>e</p>
                <p>o</p>
                <p>s</p>
                <p class="videos_header_title_number" :number="title_number">{{json_videos_data.length}}</p>
            </div>
            <div class="videos_header_types">
                <p>mashup</p>
                <p>trailer</p>
                <p>Promotional video</p>
                <p>Tutorials</p>
                <p>Creative video</p>
            </div>
        </div>
        <div class="videos_coverbox">
            <div class="videos_coverbox_cover" v-for="(video, index) in json_videos_data" :key="index" @click="videoview.show(index)">
                <div class="vcc_screen">
                    <div class="vcc_screen_pictures">
                        <img v-for="(item, index) in 6" :key="index" :src="`https://cdn.jiejoe.com/covers/${video.imgurl}`" alt="cover" />
                    </div>
                    <div class="vcc_screen_texts">
                        <p v-for="(item, index) in 4" :key="index">no signal</p>
                    </div>
                    <svg class="vcc_screen_lines" viewBox="0 0 960 600">
                        <rect v-for="(item, index) in 10" :key="index" y="-150" width="960" height="100" :style="{ '--i': index }" />
                    </svg>
                    <img class="vcc_screen_nosign" :src="require('../assets/images/videos/no_sign.svg')" alt="nosign" />
                </div>
                <p class="vcc_information">
                    {{video.name_zh}}
                    <span>|</span>
                    {{video.time}}
                </p>
                <p class="vcc_name">
                    <span :letter="letter.charCodeAt()" v-for="(letter, index) in video.name_eng.split('')" :key="index" :style="{ '--i': index }"></span>
                </p>
            </div>
        </div>
        <div class="videos_feeter">
            <div class="videos_feeter_tv" @click="back_to_top">
                <svg viewBox="0 0 1125 780" :class="{'image_wave':image_wave.if_open}">
                    <g>
                        <image width="1125" height="780" :href="require('../assets/images/videos/tv.svg')" />
                        <polygon points="626.14,389.86 539.72,389.86 539.72,543.29 375.06,543.29 375.06,389.86 288.64,389.86 457.39,198.74" />
                    </g>
                </svg>
                <p>back to top</p>
            </div>
            <div class="videos_feeter_information">
                <p>Do not go gentle into that good night. Old age should burn and rave at close of day; Rage, rage against the dying of the light.</p>
                <p>When fortune smiles on something as violent and ugly as revenge, it seems proof like no other that not only does God exist, you're doing His will.</p>
                <p>I have to believe that when my eyes are closed, the world's still there.</p>
                <p>I hope you live a life you're proud of. If you find that you're not, I hope you have the strength to start all over again.</p>
            </div>
            <div class="videos_feeter_bottom">
                <div class="vfb_plus"></div>
                <div class="vfb_plus"></div>
                <div class="vfb_plus"></div>
                <div class="vfb_time">
                    <p>since 2019</p>
                    <img :src="require('../assets/images/videos/text_line.svg')" alt="text_line" />
                </div>
                <div class="vfb_plus"></div>
                <div class="vfb_plus"></div>
                <div class="vfb_plus"></div>
            </div>
        </div>
        <div class="videos_videoview" v-show="videoview.if_visible">
            <div class="videos_videoview_background" @click="videoview.hidden()"></div>
            <div class="videos_videoview_videobox" @click="videoview.swich_play_state()">
                <video
                    loop
                    class="videos_videoview_videobox_video"
                    :src="videoview.current_video_index<0?null:json_videos_data[videoview.current_video_index].video_url"
                ></video>
                <div class="videos_videoview_videobox_mask"></div>
            </div>
            <svg
                class="videos_videoview_mousetip"
                viewBox="0 0 500 500"
                v-if="videoview.if_mousetip_render"
                :class="{
              'videos_videoview_mousetip_play':!videoview.if_shutable && !videoview.if_playing,
              'videos_videoview_mousetip_pause':!videoview.if_shutable && videoview.if_playing,
              'videos_videoview_mousetip_shut':videoview.if_shutable,
            }"
            >
                <g class="vvm_icon">
                    <path
                        class="vvm_icon_left"
                        d="M250,431.11L250,431.11c-22.32,0-40.42-18.1-40.42-40.42V109.31c0-22.32,18.1-40.42,40.42-40.42h0
	c22.32,0,40.42,18.1,40.42,40.42v281.38C290.42,413.01,272.32,431.11,250,431.11z"
                    />
                    <g class="vvm_icon_right">
                        <path
                            class="vvm_icon_right_up"
                            d="M250,290.25L250,290.25c-22.32,0-40.42-18.1-40.42-40.42V109.31c0-22.32,18.1-40.42,40.42-40.42h0
		c22.32,0,40.42,18.1,40.42,40.42v140.51C290.42,272.15,272.32,290.25,250,290.25z"
                        />
                        <path
                            class="vvm_icon_right_down"
                            d="M250,431.11L250,431.11c-22.32,0-40.42-18.1-40.42-40.42V250.18c0-22.32,18.1-40.42,40.42-40.42h0
		c22.32,0,40.42,18.1,40.42,40.42v140.51C290.42,413.01,272.32,431.11,250,431.11z"
                        />
                    </g>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import Scrollbar from "@/components/scrollbar.vue";
import gsap from "gsap";
export default {
    name: "videos",
    components: {
        Scrollbar,
    },
    data() {
        return {
            json_videos_data: require("../assets/json/videos_data.json"),
            animation_data: [], //每一个视频封面的动画播放数据
            observer: null, //观察者
            title_number: 0, //页面标题中的视频数量
            tv: null, //feeter电视机DOM对象
            // 图像波动滤镜
            image_wave: {
                id: null, // requestAnimationFrameID
                seed: 0, //随机数种子
                scale: 0, //波动大小
                scale_nums: 0, //波动相对于屏幕的缩放数值
                //是否开启图像波动：主要用于区别safari浏览器关闭波动，否则safari会出现电视不见和文字抖动的BUG
                if_open: true,
            },
            // 动画控制器
            animation_controler: {
                // 用于播放的所有阶段电视动画序列
                animations: [],
                // 控制wave滤镜的seed和scale:制造动态滤波效果
                wave_seed: 0,
                wave_scale: 0,
                init() {
                    // 每一部分动画包含动画执行函数,clear函数,以及持续时间
                    this.animations = [
                        {
                            fun: this.picture_fault.bind(this),
                            duration: 300,
                            clear: this.clear_picture_fault.bind(this),
                        },
                        {
                            fun: this.text_fault.bind(this),
                            duration: 300,
                            clear: this.clear_text_fault.bind(this),
                        },
                        {
                            fun: this.text_shake.bind(this),
                            duration: 300,
                            clear: this.clear_text_shake.bind(this),
                        },
                        {
                            fun: this.text_fault.bind(this),
                            duration: 100,
                            clear: this.clear_text_fault.bind(this),
                        },
                        {
                            fun: this.screen_line.bind(this),
                            duration: 300,
                            clear: this.clear_screen_line.bind(this),
                        },
                        {
                            fun: this.scree_nosign.bind(this),
                            duration: 150,
                            clear: this.clear_scree_nosign.bind(this),
                        },
                        {
                            fun: this.screen_wave.bind(this),
                            duration: 250,
                            clear: this.clear_screen_wave.bind(this),
                        },
                        {
                            fun: this.recovery.bind(this),
                            duration: null,
                            clear: null,
                        },
                    ];
                },
                // 抖动动画，将传入的对象进行随机clip裁剪和抖动
                faultshake(eles) {
                    eles.forEach((ele) => {
                        // 随机xy平移抖动
                        ele.style.transform = `translate(
                            ${Math.random() * 60 - 30}%,
                            ${Math.random() * 60 - 30}%)`;
                        // 随机clip裁剪
                        let x = Math.random() * 100;
                        let y = Math.random() * 100;
                        let h = Math.random() * 50 + 50;
                        let w = Math.random() * 40 + 10;
                        ele.style.clipPath = `polygon(
                            ${x}% ${y}%,
                            ${x + w}% ${y}%,
                            ${x + w}% ${y + h}%,
                            ${x}% ${y + h}%)`;
                    });
                },
                // 图像RGB故障动画
                picture_fault(animation) {
                    animation.pictures.style.visibility = "visible";
                    animation.pictures.classList.add("vcc_screen_pictures_fault");
                    this.faultshake(animation.pictures_img);
                },
                // 清除图像RGB故障动画
                clear_picture_fault(animation) {
                    animation.pictures.style.visibility = "hidden";
                    animation.pictures.classList.remove("vcc_screen_pictures_fault");
                    animation.pictures_img.forEach((ele) => {
                        ele.style = "";
                    });
                },
                // 文字RGB故障动画
                text_fault(animation) {
                    animation.texts.style.visibility = "visible";
                    animation.texts.classList.add("vcc_screen_texts_fault");
                    this.faultshake(animation.texts_p);
                },
                clear_text_fault(animation) {
                    animation.texts.style.visibility = "hidden";
                    animation.texts.classList.remove("vcc_screen_texts_fault");
                    animation.texts_p.forEach((text) => {
                        text.style = "";
                    });
                },
                // 文字抖动动画
                text_shake(animation) {
                    animation.texts.style.visibility = "visible";
                    animation.texts.classList.add("vcc_screen_texts_shake");
                    animation.texts_p.forEach((p) => {
                        p.style.transform = `translate(
                        ${Math.random() * 4 - 2}%,
                        ${Math.random() * 4 - 2}%)`;
                    });
                },
                clear_text_shake(animation) {
                    animation.texts.style.visibility = "hidden";
                    animation.texts.classList.remove("vcc_screen_texts_shake");
                    animation.texts_p.forEach((p) => {
                        p.style = "";
                    });
                },
                // 电视条纹抖动动画
                screen_line(animation) {
                    animation.pictures.style.visibility = "visible";
                    animation.lines.style.visibility = "visible";
                    animation.pictures.classList.add("vcc_screen_pictures_wave");
                    animation.lines.classList.add("vcc_screen_lines_wave");
                },
                clear_screen_line(animation) {
                    animation.pictures.style.visibility = "hidden";
                    animation.lines.style.visibility = "hidden";
                    animation.pictures.classList.remove("vcc_screen_pictures_wave");
                    animation.lines.classList.remove("vcc_screen_lines_wave");
                },
                // 电视无信号动画
                scree_nosign(animation) {
                    animation.nosign.style.visibility = "visible";
                    this.wave_seed = Math.random() * 100;
                    this.wave_scale = Math.random() * 10 + 30;
                },
                clear_scree_nosign(animation) {
                    animation.nosign.style.visibility = "hidden";
                },
                // 电视波纹抖动动画
                screen_wave(animation) {
                    animation.pictures.style.visibility = "visible";
                    animation.pictures.style.filter =
                        "grayscale(1) url(#videos_resources_wave_screen)";
                    animation.pictures.style.transform = `scale(1.5) translate( 
                    ${Math.random() * 30 - 15}%,
                    ${Math.random() * 30 - 15}%)`;
                },
                clear_screen_wave(animation) {
                    animation.pictures.style = "";
                },
                // 复位：正常的电视图像画面,用于结束动画播放
                recovery(animation) {
                    animation.pictures.style.visibility = "visible";
                },
                // 播放电视故障动画
                play_animation(animation) {
                    const play = () => {
                        // 如果当前动画段落不为第一个,即上一个动画具有clear函数,清除上一个动画的样式
                        if (animation.commander.index > 0)
                            this.animations[animation.commander.index - 1].clear(animation);
                        clearInterval(animation.player);
                        // 如果当前动画不为最后一个,则用player执行动画播放
                        if (animation.commander.index != this.animations.length - 1) {
                            let index = animation.commander.index;
                            animation.player = setInterval(() => {
                                this.animations[index].fun(animation);
                            }, 30);
                        } else {
                            // 若为最后一个,则仅执行,不用player播放,并结束整个play_animation函数,否则无法关闭
                            this.animations[animation.commander.index].fun(animation);
                            return (animation.commander.index = 0);
                        }
                        // 设置当前动画播放结束后,马上开始下一个动画的播放
                        animation.commander.timer = setTimeout(
                            play,
                            this.animations[animation.commander.index].duration
                        );
                        animation.commander.index++;
                    };
                    play();
                },
            },
            // 视频视图
            videoview: {
                // DOM对象
                container: null,
                videobox: null,
                video: null,
                mask: null,
                background: null,
                mousetip: null,
                current_video_index: -1, // 当前播放视频的索引
                if_visible: false, // videoview是否可见
                if_playing: false, // 当前视频是否正在播放中
                if_shutable: false, // 当前能否关闭视频
                if_loadover: false, // 视频资源是否加载完毕
                if_animating: false, // 当前视频页是否处于动画变化过程中
                if_mousetip_render: true, //是否渲染显示mousetip
                init() {
                    this.container = document.querySelector(".videos_videoview");
                    this.videobox = document.querySelector(".videos_videoview_videobox");
                    this.video = document.querySelector(".videos_videoview_videobox_video");
                    this.mask = document.querySelector(".videos_videoview_videobox_mask");
                    this.background = document.querySelector(".videos_videoview_background");
                    // 如果屏幕触点不止一个，则判断为移动端手势，不渲染mousetip
                    if (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
                        return (this.if_mousetip_render = false);
                    this.mousetip = document.querySelector(".videos_videoview_mousetip");
                    this.bind_mousetip_events();
                },
                // 绑定mousetip的事件
                bind_mousetip_events() {
                    this.container.onmousemove = (e) => {
                        this.mov_mousetip(e);
                    };
                    this.container.onmouseenter = (e) => {
                        this.show_mousetip(e);
                    };
                    this.container.onmouseleave = () => {
                        this.hidden_mousetip();
                    };
                    this.background.onmouseenter = () => {
                        this.if_shutable = true; //鼠标移入背景：点击可关闭视频
                    };
                    this.background.onmouseleave = () => {
                        this.if_shutable = false; //鼠标移出背景：不可关闭视频
                    };
                },
                // 移动mousetip
                mov_mousetip(e) {
                    gsap.to(this.mousetip, {
                        x: `${e.x}px`,
                        y: `${e.y}px`,
                        duration: 0.5,
                        ease: "power2.out",
                    });
                },
                // 显示mousetip
                show_mousetip(e) {
                    gsap.timeline().to(this.mousetip, {
                        x: `${e.x}px`,
                        y: `${e.y}px`,
                        scale: 1,
                        duration: 0.8,
                        ease: "power3.out",
                    });
                },
                // 隱藏mousetip
                hidden_mousetip() {
                    gsap.to(this.mousetip, {
                        scale: 0,
                        duration: 0.8,
                        ease: "power3.out",
                    });
                },
                // 切换视频的播放状态
                swich_play_state() {
                    // 视频未加载完毕：不可以切换视频播放状态
                    if (!this.if_loadover) return;
                    if (this.if_playing) {
                        this.if_playing = false;
                        this.video.pause();
                    } else {
                        this.if_playing = true;
                        this.video.play();
                    }
                },
                // 显示动画
                show(index) {
                    if (this.if_animating) return; // 若动画正在进行中，则退出
                    this.if_animating = true;
                    // 判断视频是否发生变化
                    if (this.current_video_index != index) {
                        // 视频变化，更新序号，以及视频加载状态
                        this.current_video_index = index;
                        this.if_loadover = false;
                        this.video.onloadeddata = () => {
                            // 视频加载完成之后才播放
                            this.if_loadover = true;
                            this.if_playing = true;
                            this.video.play();
                        };
                    } else {
                        // 视频没有更新：则直接播放
                        this.if_playing = true;
                        this.video.play();
                    }
                    // 视频显示动画
                    this.if_visible = true;
                    gsap.timeline()
                        .to(this.background, {
                            opacity: 1,
                            duration: 0.5,
                            ease: "power3.out",
                        })
                        .to(
                            this.videobox,
                            {
                                "clip-path": "polygon(0% 0%, 5% 0%, 5% 100%, 0% 100%)",
                                duration: 0.5,
                                ease: "power4.out",
                            },
                            "<"
                        )
                        .to(
                            this.videobox,
                            {
                                "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                                duration: 0.6,
                                ease: "power4.out",
                            },
                            "<0.3"
                        )
                        .to(
                            this.mask,
                            {
                                "clip-path": "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
                                duration: 0.5,
                                ease: "power4.out",
                                onComplete: () => {
                                    this.if_animating = false;
                                },
                            },
                            "<0.4"
                        );
                },
                // 隐藏动画
                hidden() {
                    // 若动画正在进行中，则退出
                    if (this.if_animating) return;
                    if (this.if_mousetip_render) this.hidden_mousetip(); //若mousetip渲染，则隐藏mousetip
                    this.if_animating = true;
                    this.if_playing = false;
                    this.video.pause();
                    // 播放当前视频封面的故障动画
                    this.animation_controler.play_animation(
                        this.animation_data[this.current_video_index]
                    );
                    // 画面闪烁动画
                    gsap.timeline()
                        .set(this.mask, {
                            "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                        })
                        .set(this.container, {
                            opacity: 0,
                        })
                        .set(
                            this.container,
                            {
                                opacity: 1,
                            },
                            "<0.05"
                        )
                        .set(
                            this.container,
                            {
                                opacity: 0,
                            },
                            "<0.05"
                        )
                        .set(
                            this.container,
                            {
                                opacity: 1,
                            },
                            "<0.1"
                        );
                    // 视频压缩动画
                    gsap.timeline()
                        .to(this.background, {
                            opacity: 0,
                            duration: 1,
                            ease: "power3.out",
                            onComplete: () => {
                                this.if_animating = false;
                                this.if_visible = false;
                                // 复位动画属性
                                gsap.set(this.videobox, {
                                    "clip-path": "polygon(0% 0%, 5% 0%, 5% 0%, 0% 0%)",
                                    transform: "scale(1)",
                                });
                            },
                        })
                        .to(
                            this.videobox,
                            {
                                transform: "scale(1.5,0.03)",
                                duration: 0.3,
                                ease: "power4.out",
                            },
                            "<"
                        )
                        .to(
                            this.videobox,
                            {
                                transform: "scale(0)",
                                duration: 0.15,
                                ease: "power4.out",
                            },
                            "<0.2"
                        );
                },
                remove() {
                    this.container = null;
                    this.videobox = null;
                    this.video = null;
                    this.mask = null;
                    this.background = null;
                    this.mousetip = null;
                },
            },
        };
    },
    methods: {
        // 创建动画数据
        creat_animation_data() {
            let cover = [...document.querySelectorAll(".videos_coverbox_cover")];
            let information = [...document.querySelectorAll(".vcc_information")];
            let pictures = [...document.querySelectorAll(".vcc_screen_pictures")];
            let texts = [...document.querySelectorAll(".vcc_screen_texts")];
            let lines = [...document.querySelectorAll(".vcc_screen_lines")];
            let nosign = [...document.querySelectorAll(".vcc_screen_nosign")];
            this.json_videos_data.forEach((data, index) => {
                // 创建视频数据
                this.animation_data.push({
                    player: null, //动画播放器
                    // 动画指挥器,用于控制动画按照一定时间一段一段按顺序播放
                    commander: {
                        timer: null,
                        index: 0,
                    },
                    // DOM对象:
                    cover: cover[index],
                    information: information[index],
                    letters: [...cover[index].querySelectorAll(".vcc_name span")],
                    // 该部分screen元素将用来执行故障动画效果
                    pictures: pictures[index],
                    pictures_img: [...pictures[index].querySelectorAll("img")],
                    texts: texts[index],
                    texts_p: [...texts[index].querySelectorAll("p")],
                    lines: lines[index],
                    nosign: nosign[index],
                });
            });
        },
        // 回收动画数据
        remove_animation_data() {
            this.animation_data.forEach((data) => {
                clearInterval(data.player);
                clearTimeout(data.commander.timer);
                data.player = null;
                data.commander = null;
                data.cover = null;
                data.information = null;
                data.letters = null;
                data.pictures = null;
                data.pictures_img = null;
                data.texts = null;
                data.texts_p = null;
                data.lines = null;
                data.nosign = null;
            });
            this.animation_data = null;
        },
        // 创建观察者，制作响应动画
        creat_observer() {
            this.observer = new IntersectionObserver(
                (eles) => {
                    eles.forEach((ele) => {
                        if (ele.isIntersecting) {
                            // 播放故障动画
                            let index = ele.target.index;
                            this.animation_controler.play_animation(this.animation_data[index]);
                            ele.target.style.visibility = "visible";
                            // 文字信息色块动画
                            this.animation_data[index].information.classList.add(
                                "vcc_information_show"
                            );
                            // 字母乱码动画
                            this.animation_data[index].letters.forEach((letter, index) => {
                                const ease = { nums: 65 };
                                gsap.to(ease, {
                                    nums: parseInt(letter.getAttribute("letter")), //将letter从a过渡到当前对应字母码
                                    duration: 0.3,
                                    ease: "power3.out",
                                    delay: index * 0.05,
                                    onUpdate: () => {
                                        letter.innerText = String.fromCharCode(parseInt(ease.nums)); //将字母码转换并更新为字母
                                    },
                                    onComplete: () => {
                                        letter.style.color = "var(--theme_white)";
                                    },
                                });
                            });
                            this.observer.unobserve(ele.target);
                        }
                    });
                },
                {
                    rootMargin: `${-innerHeight / 2}px 0px`,
                }
            );
            this.animation_data.forEach((animation, index) => {
                animation.cover.index = index;
                this.observer.observe(animation.cover);
            });
        },
        // 注销观察者，释放资源
        remove_observer() {
            this.animation_data.forEach((animation) => {
                this.observer.unobserve(animation.cover);
            });
        },
        // 创建图像波动效果
        creat_image_wave() {
            if (!this.image_wave.if_open) return;
            const shake = () => {
                this.image_wave.seed = Math.random() * 100;
                this.image_wave.scale = Math.random() * this.image_wave.scale_nums;
                this.image_wave.id = requestAnimationFrame(shake);
            };
            this.image_wave.id = requestAnimationFrame(shake);
        },
        // 重置波动基本数值：避免屏幕太多或太小时，波动幅度过小或过大
        reset_scale_nums() {
            this.image_wave.scale_nums =
                15 + 5 * (innerWidth / 1000 - 1 + (innerHeight / 1000 - 1)); // 以屏幕的宽高为基准计算
        },
        // 开屏动画
        init_animation() {
            const ease = { nums: 0 };
            gsap.timeline()
                .to(".videos_header_title p", {
                    transform: "translateY(0%)",
                    duration: 1,
                    ease: "power4.out",
                    stagger: 0.05,
                })
                .to(
                    ".videos_header_types p",
                    {
                        transform: "translateX(0%)",
                        opacity: 1,
                        duration: 1,
                        ease: "power2.out",
                        stagger: 0.05,
                    },
                    "<"
                )
                .to(
                    ease,
                    {
                        nums: this.json_videos_data.length,
                        duration: 0.5,
                        ease: "linear",
                        onUpdate: () => {
                            this.title_number = parseInt(ease.nums);
                        },
                    },
                    "<0.4"
                );
        },
        // 回到页面顶部
        back_to_top() {
            // feeter电视机向下蓄力然后向上运动，页面回到顶部
            gsap.timeline()
                .to(this.tv, {
                    y: `30%`,
                    duration: 0.5,
                    ease: "power3.out",
                })
                .to(this.tv, {
                    y: `-50%`,
                    duration: 0.8,
                    ease: "power4.out",
                    onComplete: () => {
                        gsap.set(this.tv, {
                            y: `0%`,
                        });
                    },
                })
                .to(
                    this.tv,
                    {
                        // 延迟0.1秒后，页面回到顶部
                        onStart: () => {
                            this.$parent.scroll_controler.scrollTo(0, { lock: true });
                        },
                    },
                    "<0.1"
                );
        },
    },
    mounted() {
        if (
            navigator.userAgent.includes("Safari") &&
            !navigator.userAgent.includes("Chrome") &&
            !navigator.userAgent.includes("Chromium")
        )
            // 如果当前浏览器是Safari：则不启用图像波动效果
            this.image_wave.if_open = false;
        this.$parent.init_animation = this.init_animation;
        this.$parent.scroll_controler.resize(); // 从contact和photos进入videos的时候，页面高度变化lenis可能反应不过来，故需要手动调用一次resize
        this.tv = document.querySelector(".videos_feeter_tv svg");
        this.animation_controler.init();
        this.videoview.init();
        this.creat_animation_data();
        this.creat_observer();
        this.creat_image_wave();
        this.reset_scale_nums();
        window.addEventListener("resize", this.reset_scale_nums);
        // videoview会调用到animation_controler的相应功能
        this.videoview.animation_controler = this.animation_controler;
        this.videoview.animation_data = this.animation_data;
    },
    beforeDestroy() {
        this.$parent.init_animation = () => {};
        this.tv = null;
        this.animation_controler.animations = null;
        this.animation_controler = null;
        this.videoview.remove();
        this.remove_observer();
        this.remove_animation_data();
        cancelAnimationFrame(this.image_wave.id);
        this.image_wave.id = null;
        this.image_wave = null;
        window.removeEventListener("resize", this.reset_scale_nums);
    },
};
</script>

<style>
#videos {
    position: absolute;
    flex-direction: column;
    width: 100%;
    background-color: var(--theme_black);
}
.image_wave {
    filter: url(#videos_resources_wave_image);
}

.videos_header {
    --scale: 1;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(var(--scale) * 3rem);
    margin-top: calc(var(--scale) * 8rem);
}
.videos_header_title {
    justify-content: center;
    transform: scaleY(0.8);
    overflow: hidden;
}

.videos_header_title p {
    font-family: eng;
    font-size: calc(var(--scale) * 18rem);
    color: var(--theme_white);
    letter-spacing: calc(var(--scale) * 0.8rem);
    line-height: calc(var(--scale) * 19rem);
    text-transform: uppercase;
    transform: translateY(100%);
}

.videos_header_title_number {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(var(--scale) * 4rem);
    color: #00000000 !important;
}
.videos_header_title_number::after {
    content: attr(number);
    position: absolute;
    font-family: eng;
    font-size: calc(var(--scale) * 18rem);
    letter-spacing: calc(var(--scale) * 0.8rem);
    line-height: calc(var(--scale) * 19rem);
    color: #00000000 !important;
    -webkit-text-stroke: calc(var(--scale) * 0.1rem) var(--theme_green);
}
.videos_header_types {
    justify-content: space-between;
    align-items: center;
}
.videos_header_types p {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 calc(var(--scale) * 1.5rem);
    font-family: eng;
    font-size: calc(var(--scale) * 1.3rem);
    color: var(--theme_green);
    letter-spacing: calc(var(--scale) * 0.1rem);
    text-transform: uppercase;
    transform: translateX(-20%);
    opacity: 0;
}
.videos_header_types p::after {
    content: "";
    position: relative;
    width: calc(var(--scale) * 0.3rem);
    height: calc(var(--scale) * 0.3rem);
    margin-left: calc(var(--scale) * 3rem);
    border-radius: 50%;
    background-color: var(--theme_white);
}
.videos_header_types p:last-child::after {
    width: 0;
    height: 0;
    margin-left: 0;
}
@media screen and (max-aspect-ratio: 0.9/1) {
    .videos_header {
        --scale: 0.75;
    }
}
@media screen and (max-aspect-ratio: 0.7/1) {
    .videos_header {
        --scale: 0.8;
    }
}
@media screen and (max-aspect-ratio: 0.65/1) {
    .videos_header {
        --scale: 0.65;
        margin-top: calc(var(--scale) * 12rem);
    }
}
@media screen and (max-aspect-ratio: 0.52/1) {
    .videos_header {
        --scale: 0.5;
        margin-top: calc(var(--scale) * 18rem);
    }
}
@media screen and (max-aspect-ratio: 0.4/1) {
    .videos_header {
        --scale: 0.35;
        margin-top: calc(var(--scale) * 24rem);
    }
}
.videos_coverbox {
    --scale: 1;
    display: grid;
    position: relative;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, auto);
    margin-bottom: calc(var(--scale) * 5rem);
}
.videos_coverbox_cover {
    position: relative;
    flex-direction: column;
    align-items: start;
    cursor: pointer;
    margin: calc(var(--scale) * 6rem) calc(var(--scale) * 3rem);
    visibility: hidden;
}
.vcc_screen {
    position: relative;
    width: calc(16 * 2.5rem * var(--scale));
    height: calc(10 * 2.5rem * var(--scale));
    border-radius: 2em;
    overflow: hidden;
    margin-bottom: calc(var(--scale) * 2.5rem);
    transition: transform 0.6s ease, border-radius 0.4s ease;
}
.vcc_screen_pictures,
.vcc_screen_texts,
.vcc_screen_lines,
.vcc_screen_nosign {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
}
.vcc_screen_pictures {
    background-color: var(--theme_white);
    filter: grayscale(1);
    transition: filter 0.5s ease;
}
.vcc_screen_pictures img {
    position: absolute;
    height: 100%;
}
.vcc_screen_pictures_fault {
    filter: grayscale(0);
    transition: filter 0s ease;
}

.vcc_screen_pictures_fault img:nth-child(1) {
    mix-blend-mode: darken;
    filter: url(#videos_resources_fillcolor_red);
}

.vcc_screen_pictures_fault img:nth-child(2) {
    mix-blend-mode: darken;
    filter: url(#videos_resources_fillcolor_lightblue);
}

.vcc_screen_pictures_fault img:nth-child(3) {
    mix-blend-mode: darken;
    filter: url(#videos_resources_fillcolor_deepblue);
}

.vcc_screen_pictures_wave img {
    animation: pictures_wave 0.1s linear infinite;
}

@keyframes pictures_wave {
    0% {
        transform: translateY(-120%);
    }
    100% {
        transform: translateY(120%);
    }
}

.vcc_screen_texts {
    justify-content: center;
    align-items: center;
    background-color: var(--theme_white);
}
.vcc_screen_texts p {
    position: absolute;
    font-family: eng;
    font-size: calc(var(--scale) * 3.5rem);
    color: var(--theme_black);
    letter-spacing: calc(var(--scale) * 0.1rem);
    text-transform: uppercase;
    mix-blend-mode: darken;
}

.vcc_screen_texts_fault p::after,
.vcc_screen_texts_fault p::before {
    content: "no signal";
    position: absolute;
    mix-blend-mode: darken;
}

.vcc_screen_texts_fault p::after {
    color: #ff000080;
    transform: translateX(1%);
}

.vcc_screen_texts_fault p::before {
    color: #0000ff80;
    transform: translateX(-1%);
}

.vcc_screen_texts_shake p:nth-child(2) {
    color: #ff0000;
}

.vcc_screen_texts_shake p:nth-child(3) {
    color: #002aff;
}

.vcc_screen_texts_shake p:nth-child(4) {
    color: #00ff3c;
}

.vcc_screen_lines rect {
    fill: var(--theme_black);
    opacity: 0.5;
    animation: rect_mov 1s linear infinite;
    animation-delay: calc(var(--i) * 0.1s);
    animation-play-state: paused;
}

.vcc_screen_lines_wave rect {
    animation-play-state: running;
}

@keyframes rect_mov {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(300%);
    }
}

.vcc_screen_nosign {
    transform: scale(1.5);
    filter: url(#videos_resources_wave_screen) grayscale(0.4);
}
@media (hover: hover) {
    .videos_coverbox_cover:hover .vcc_screen_pictures {
        filter: grayscale(0);
    }
    .videos_coverbox_cover:hover .vcc_screen {
        transform: scale(0.9);
        border-radius: 0;
    }
}
.vcc_information {
    position: relative;
    display: flex;
    align-items: center;
    font-family: zh;
    font-size: calc(var(--scale) * 1rem);
    color: var(--theme_white);
    letter-spacing: calc(var(--scale) * 0.2rem);
    margin-bottom: calc(var(--scale) * 1rem);
    transition: clip-path 0.3s ease 0.5s;
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}
.vcc_information span {
    font-size: calc(var(--scale) * 0.8rem);
    margin: 0 calc(var(--scale) * 1.2rem);
}
.vcc_information::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme_green);
    transition: clip-path 0.3s ease 0.8s;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.vcc_information_show {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.vcc_information_show::after {
    clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
}
.vcc_name span {
    font-family: eng;
    font-size: calc(var(--scale) * 3rem);
    color: var(--theme_green);
    margin-right: calc(var(--scale) * 0.05rem);
}

@media screen and (max-aspect-ratio: 1.8/1) {
    .videos_coverbox {
        --scale: 0.8;
    }
}
@media screen and (max-aspect-ratio: 1.5/1) {
    .videos_coverbox {
        --scale: 1;
    }
}
@media screen and (max-aspect-ratio: 0.9/1) {
    .videos_coverbox {
        --scale: 0.75;
    }
}
@media screen and (max-aspect-ratio: 0.7/1) {
    .videos_coverbox {
        --scale: 1.45;
        grid-template-columns: repeat(1, auto);
    }
}

@media screen and (max-aspect-ratio: 0.65/1) {
    .videos_coverbox {
        --scale: 1.15;
    }
}
@media screen and (max-aspect-ratio: 0.52/1) {
    .videos_coverbox {
        --scale: 0.85;
    }
}
@media screen and (max-aspect-ratio: 0.4/1) {
    .videos_coverbox {
        --scale: 0.55;
    }
}
.videos_feeter {
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 3.5rem;
    padding-top: 3rem;
    overflow: hidden;
}
.videos_feeter_tv {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    cursor: pointer;
}
.videos_feeter_tv svg {
    width: 10rem;
    overflow: visible;
    transform: translateY(1%);
}
.videos_feeter_tv svg g {
    transform-origin: center;
    transform: translateY(0%) rotate(3deg);
    animation: feeter_tv_g 3s ease infinite;
}
.videos_feeter_tv svg polygon {
    fill: var(--theme_green);
    transform-origin: 457.39px 371.015px;
    animation: feeter_tv_polygon 2s ease-in-out infinite;
}
@keyframes feeter_tv_g {
    0% {
        transform: translateY(0%) rotate(3deg);
    }

    50% {
        transform: translateY(10%) rotate(-3deg);
    }

    100% {
        transform: translateY(0%) rotate(3deg);
    }
}
@keyframes feeter_tv_polygon {
    0% {
        transform: translateY(0%);
    }

    25% {
        transform: translateY(-5%);
    }

    50% {
        transform: translateY(0%);
    }

    75% {
        transform: translateY(-5%);
    }
}
.videos_feeter_tv p {
    font-family: eng;
    font-size: 4.5rem;
    color: var(--theme_green);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    transform-origin: bottom;
    transition: transform 0.5s ease;
}
@media (hover: hover) {
    .videos_feeter_tv:hover p {
        transform: translateY(10%) scaleY(0.95);
    }
}

.videos_feeter_information {
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
    margin: 0 1rem 10rem 1rem;
}
.videos_feeter_information p {
    --scale: 1.2;
    width: calc(11rem * var(--scale));
    font-family: zh;
    font-size: calc(0.7rem * var(--scale));
    color: var(--theme_white);
    line-height: calc(1.2rem * var(--scale));
    letter-spacing: calc(0.1rem * var(--scale));
    text-align: start;
    margin: 1rem;
    transform-origin: top;
    transform: scale(calc(1 / var(--scale)));
}
.videos_feeter_bottom {
    justify-content: center;
    align-items: center;
}
.vfb_plus {
    position: relative;
    width: 0.6rem;
    height: 0.12rem;
    background-color: var(--theme_white);
    margin: 0 2rem;
}
.vfb_plus::after {
    content: "";
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--theme_white);
    transform: rotate(90deg);
}
.vfb_time {
    flex-direction: column;
    margin: 0 3rem;
}
.vfb_time p {
    font-family: eng;
    font-size: 1.5rem;
    color: var(--theme_white);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin-bottom: -0.4rem;
}
.vfb_time img {
    width: 8rem;
}

.videos_videoview {
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.videos_videoview_background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #171717f7;
    opacity: 0;
    cursor: pointer;
}
.videos_videoview_videobox {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: calc(19.2rem * 3);
    height: calc(10.8rem * 3);
    clip-path: polygon(0% 0%, 5% 0%, 5% 0%, 0% 0%);
    cursor: pointer;
}

.videos_videoview_videobox_video {
    position: absolute;
    height: 100%;
    background-color: var(--theme_black);
}
.videos_videoview_videobox_mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--theme_green);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
@media screen and (max-aspect-ratio: 1.5/1) {
    .videos_videoview_videobox {
        width: calc(19.2rem * 4);
        height: calc(10.8rem * 4);
    }
}
@media screen and (max-aspect-ratio: 1/1) {
    .videos_videoview_videobox {
        width: calc(19.2vmin * 4.6);
        height: calc(10.8vmin * 4.6);
    }
}
.videos_videoview_mousetip {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 5rem;
    padding: 1rem;
    background-color: var(--theme_green);
    border-radius: 50%;
    margin-left: -2.5rem;
    margin-top: -2.5rem;
    transform: scale(0);
    pointer-events: none;
}
.videos_videoview_mousetip path {
    fill: var(--theme_black);
}
.vvm_icon,
.vvm_icon_left,
.vvm_icon_right,
.vvm_icon_right_up,
.vvm_icon_right_down {
    transform-origin: 250px 250px;
    transition: transform 0.3s ease-in-out;
}

.videos_videoview_mousetip_shut .vvm_icon_left {
    transform: rotate(-45deg);
}
.videos_videoview_mousetip_shut .vvm_icon_right {
    transform: rotate(45deg);
}
.videos_videoview_mousetip_pause .vvm_icon_left {
    transform: translateX(-20%);
}
.videos_videoview_mousetip_pause .vvm_icon_right {
    transform: translateX(20%);
}
.videos_videoview_mousetip_play .vvm_icon {
    transform: scale(1.35);
}
.videos_videoview_mousetip_play .vvm_icon_left {
    transform: translateX(-6.5%) scaleY(0.71);
}
.videos_videoview_mousetip_play .vvm_icon_right {
    transform: translateX(20%);
}
.videos_videoview_mousetip_play .vvm_icon_right_up {
    transform: rotate(-55deg);
}
.videos_videoview_mousetip_play .vvm_icon_right_down {
    transform: rotate(55deg);
}
</style>
