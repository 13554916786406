<template>
    <div id="contact">
        <div class="contact_form">
            <p class="contact_form_title">get in touch :)</p>
            <p class="contact_form_subtitle">交个朋友？谈场合作？联系我，或者我联系你</p>
            <div class="contact_form_input">
                <p>
                    你的称呼 /
                    <span>your name</span>
                </p>
                <input type="text" @focus="if_focus = true" @blur="if_focus = false" />
            </div>
            <div class="contact_form_input">
                <p>
                    你的邮箱 /
                    <span>your Email</span>
                </p>
                <input type="text" @focus="if_focus = true" @blur="if_focus = false" />
            </div>
            <div class="contact_form_input">
                <p>
                    有何贵干 /
                    <span>your need</span>
                </p>
                <input type="text" @focus="if_focus = true" @blur="if_focus = false" />
            </div>
            <div class="contact_form_submit">
                <div class="cfs_QR">
                    <img :src="'./QR/email.png'" alt="email" />
                    <p>Email</p>
                </div>
                <div class="cfs_QR">
                    <img :src="'./QR/qq.png'" alt="qq" />
                    <p>QQ</p>
                </div>
                <div class="cfs_QR">
                    <img :src="'./QR/wechat.png'" alt="wechat" />
                    <p>WeChat</p>
                </div>
                <div class="cfs_button" @click="submit"></div>
            </div>
        </div>
        <div class="contact_animation"></div>
    </div>
</template>

<script>
import gsap from "gsap";
import lottie from "lottie-web";
export default {
    name: "contact",
    data() {
        return {
            animation_player: {},
            // 所有的输入框DOM元素
            inputs: null,
            // 当前是否有输入框处于focus：用于判断用户是否点击输入框
            if_focus: false,
            // 是否处于提交过程中
            if_submiting: false,
        };
    },
    methods: {
        init() {
            this.inputs = [...document.querySelectorAll(".contact_form_input input")];
            this.create_animation();
        },
        // 创建动画
        create_animation() {
            // 加载lottie动画
            this.animation_player = lottie.loadAnimation({
                container: document.querySelector(".contact_animation"),
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: require("../assets/animations/contact.json"),
            });
            // 监听动画播放：动画循环播放，当用户点击输入框的时候，会触发对应动画事件
            this.animation_player.addEventListener("enterFrame", () => {
                // 访客是否在输入
                if (!this.if_focus) {
                    if (
                        this.animation_player.currentFrame >= 91 &&
                        this.animation_player.currentFrame <= 110
                    )
                        this.animation_player.goToAndPlay(0, true); // 未输入，重复播放动画
                } else {
                    // 访客输入，重复播放110-140帧动画
                    if (this.animation_player.currentFrame >= 140)
                        this.animation_player.goToAndPlay(110, true);
                }
            });
        },
        // 开屏动画
        show() {
            gsap.timeline()
                .to(".contact_form_input", {
                    transform: `translateX(0%)`,
                    duration: 1,
                    ease: "power4.out",
                    stagger: 0.05,
                })
                .to(
                    ".cfs_button",
                    {
                        transform: `scale(1)`,
                        duration: 0.8,
                        ease: "power4.out",
                    },
                    "<0.5"
                );
        },
        // 提交:检查输入框是否都有内容
        submit() {
            if (this.if_submiting) return; //处于提交过程中，则不检查
            let if_postable = true;
            this.inputs.forEach((input) => {
                if (!input.value.trim().length) {
                    // 当任意输入框未输入：则无法post，并且抖动对应输入框
                    if_postable = false;
                    this.shake_input(input);
                } else input.blur();
            });
            if (if_postable) {
                this.clear_inputs();
                this.post();
            }
        },
        // 抖动输入框
        shake_input(input) {
            gsap.timeline({ repeat: 2 })
                .to(input, {
                    rotate: "3deg",
                    duration: 0.04,
                })
                .to(input, {
                    rotate: "-3deg",
                    duration: 0.04,
                })
                .to(input, {
                    rotate: "0deg",
                    duration: 0.04,
                });
        },
        // 清空输入框内容
        clear_inputs() {
            // 播放清除动画:处于提交过程中
            this.if_submiting = true;
            gsap.timeline()
                .to(this.inputs, {
                    transform: "translateX(150%)",
                    duration: 0.4,
                    ease: "power3.inOut",
                    stagger: 0.1,
                })
                .fromTo(
                    this.inputs,
                    {
                        transform: "translateX(-150%)",
                    },
                    {
                        transform: "translateX(0)",
                        duration: 0.8,
                        ease: "power3.out",
                        stagger: 0.1,
                        immediateRender: false, //避免输入框动画立即渲染，提前出现在左侧
                        onStart: () => {
                            // 在输入框全部离开可视界面的时候，清除输入内容
                            this.inputs.forEach((input) => {
                                input.value = "";
                            });
                        },
                        onComplete: () => {
                            this.if_submiting = false; //提交过程结束
                        },
                    },
                    "<0.3"
                );
        },
        // post信息内容
        post() {
            let post_message = new XMLHttpRequest();
            post_message.open("POST", "https://api.jiejoe.com:8080/jiejoe/PutMessage", true);
            post_message.setRequestHeader("Content-Type", "application/json");
            post_message.send(
                JSON.stringify({
                    name: this.inputs[0].value,
                    email: this.inputs[1].value,
                    message: this.inputs[2].value,
                })
            );
        },
    },
    mounted() {
        // 设置初始化动画
        this.$parent.init_animation = this.show;
        this.init();
    },
    beforeDestroy() {
        this.$parent.init_animation = () => {};
        this.animation_player.destroy();
        this.inputs = null;
    },
};
</script>

<style>
#contact {
    box-sizing: border-box;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    height: 100dvh;
    padding: 3rem;
    overflow: hidden;
}

.contact_form {
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50rem;
    height: 45rem;
    padding-left: 8rem;
    background-color: var(--theme_green);
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    overflow: hidden;
    z-index: 1;
}
.contact_form_title,
.contact_form_subtitle,
.contact_form_input,
.contact_form_submit {
    width: 80%;
}
.contact_form_title {
    font-family: eng;
    font-size: 4rem;
    color: var(--theme_black);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
    white-space: nowrap;
}
.contact_form_subtitle {
    font-family: zh;
    font-size: 1rem;
    color: var(--theme_black);
    letter-spacing: 0.05rem;
    margin-bottom: 3rem;
    white-space: nowrap;
}
.contact_form_input {
    flex-direction: column;
    margin-bottom: 2rem;
    transform: translateX(-150%);
}
.contact_form_input p {
    display: flex;
    align-items: end;
    font-family: zh;
    font-size: 0.9rem;
    color: var(--theme_black);
    letter-spacing: 0.15rem;
    line-height: 0.9rem;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
}
.contact_form_input p span {
    font-family: eng;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    line-height: 0.9rem;
    text-transform: uppercase;
    margin-left: 0.4rem;
}
.contact_form_input:hover p {
    color: var(--theme_white);
}

.contact_form_input input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 3.2rem;
    border: 0.25rem solid var(--theme_black);
    border-radius: 2rem;
    background-color: var(--theme_white);
    padding-left: 2rem;
    outline: none;
    font-family: zh;
    font-size: 1rem;
    color: var(--theme_black);
    letter-spacing: 0.05rem;
    cursor: pointer;
}
.contact_form_submit {
    position: relative;
    justify-content: flex-start;
    margin-top: 1rem;
}
.cfs_QR {
    flex-direction: column;
    align-items: center;
    margin-right: 2.5rem;
}
.cfs_QR img {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 0.2rem;
}
.cfs_QR p {
    font-family: zh;
    font-size: 1rem;
    color: var(--theme_black);
    letter-spacing: 0.1rem;
    transform-origin: top;
    transform: scale(0.6);
}
.cfs_button {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0.4rem;
    right: 0;
    width: 5.2rem;
    height: 2rem;
    border: 0.25rem solid var(--theme_black);
    border-radius: 2rem;
    transform-origin: left;
    transform: scale(0);
    cursor: pointer;
}
.cfs_button::after {
    content: "SUBMIT";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 0.25rem solid var(--theme_black);
    border-radius: 2rem;
    background-color: var(--theme_white);
    font-family: eng;
    font-size: 1.2rem;
    color: var(--theme_black);
    transform: translateY(-0.4rem);
    transition: transform 0.3s ease;
}

@media (hover: hover) {
    .cfs_button:hover::after {
        transform: translateY(0);
    }
}

.contact_animation {
    box-sizing: border-box;
    position: relative;
    width: 50rem;
    height: 45rem;
    margin-left: -0.2rem;
    background-color: var(--theme_black);
    border: 0.2rem solid var(--theme_white);
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    overflow: hidden;
    z-index: 0;
}
@media screen and (max-aspect-ratio: 1.7/1) {
    .contact_form_title,
    .contact_form_subtitle,
    .contact_form_input,
    .contact_form_submit {
        width: 90%;
    }
}
@media screen and (max-aspect-ratio: 1.5/1) {
    .contact_form_title,
    .contact_form_subtitle,
    .contact_form_input,
    .contact_form_submit {
        width: 80%;
    }
}
@media screen and (max-aspect-ratio: 1/1) {
    #contact {
        flex-direction: column-reverse;
    }
    .contact_form {
        justify-content: flex-start;
        align-items: center;
        padding-left: 0;
        padding-top: 5rem;
        width: 55rem;
        height: 50rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 5rem;
        border-bottom-right-radius: 5rem;
    }

    .contact_form_title,
    .contact_form_subtitle,
    .contact_form_input,
    .contact_form_submit {
        width: 35rem;
    }

    .contact_animation {
        width: 55rem;
        height: 50rem;
        margin-left: 0;
        margin-bottom: -0.3rem;
        border-bottom-right-radius: 0;
        border-top-left-radius: 5rem;
        border-top-right-radius: 5rem;
    }
}
@media screen and (max-aspect-ratio: 0.6/1) {
    #contact {
        padding: 3rem 1rem;
    }
    .contact_form {
        width: 90%;
    }
    .contact_form_title,
    .contact_form_subtitle,
    .contact_form_input,
    .contact_form_submit {
        width: 70%;
    }

    .contact_animation {
        width: 90%;
    }
}
</style>
