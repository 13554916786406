<template>
    <div id="home">
        <Scrollbar />
        <div class="home_welcome">
            <div class="home_welcome_lines">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="home_welcome_background">
                <img class="hwb_blurball1" :src="require('../assets/images/home/blurball.webp')" alt="blurball" />
                <img class="hwb_blurball2" :src="require('../assets/images/home/blurball.webp')" alt="blurball" />
            </div>
            <div class="home_welcome_logo"></div>
            <div class="home_welcome_main">
                <div class="hwm_title">
                    <div>
                        <p>be wild!</p>
                    </div>
                    <div>
                        <p>creative!</p>
                    </div>
                    <div>
                        <p>
                            <span>and</span> cool!
                        </p>
                    </div>
                </div>
                <svg class="home_welcome_nobody" viewBox="0 0 500 500">
                    <image class="hwv_circle" width="500" height="500" :href="require('../assets/images/home/nobody_text.svg')" />
                    <circle class="hwv_eye" cx="168.5" cy="187.8" r="21.5" />
                    <circle class="hwv_eye" cx="331.5" cy="187.8" r="21.5" />
                    <path
                        class="hwv_mouse"
                        d="M374.3,307.9c0,0-7.2-15.8-27.9-13.3c-13.4,2-19.7,9.3-24.3,19.5c-4.6,
                        10.3-10.9,19.5-24.3,19.5s-20.2-8.1-24.3-19.5c-4.2-11.4-10.4-19.8-23.8-19.5c-13.4,
                        0.2-19.7,8.1-23.8,19.5s-10.9,19.5-24.3,19.5c-13.4,0-19.7-9.3-24.3-19.5s-11-17.5-24.3
                        -19.5c-20.7-2.5-27.9,13.3-27.9,13.3"
                    />
                </svg>
            </div>
            <div class="home_welcome_scrolltip">
                <svg class="hws_brand" viewBox="0 0 500 800">
                    <polygon class="hws_brand_left" points="92.6,93.3 25.2,733.1 83.6,727.8 " />
                    <path
                        class="hws_brand_right"
                        d="M380.4,79.4L108.7,44.7l8.2,755.3l97.4-12.9l14.1-69.3l140.5-17.2l29.1,
                            67l76.8-12L380.4,79.4z M330.6,143.9 l6.3,58.4l-153.1-11.6l-3.5-65.3L330.6,143.9z M136.9,
                            582.4L129,216.9l260.6,16.4l44.6,331.9L136.9,582.4z"
                    />
                    <g class="hws_brand_arrow">
                        <path d="M314.2,494.9c0,0,5.3-59.9-28.6-115.1c-35.1-57.4-86.6-65.3-86.6-65.3" />
                        <path d="M266.9,454.3c0,0,34.2,45.4,49.8,44c15.5-1.4,34.5-52.5,34.5-52.5" />
                    </g>
                </svg>
                <p>
                    - Scroll carefully, it's smooth -
                    <br />小
                    <span>·</span> 心
                    <span>·</span> 地
                    <span>·</span> 滑
                </p>
            </div>
        </div>
        <div class="home_about">
            <div class="home_about_content">
                <p class="hac_title" text="ABOUT ME">ABOUT ME</p>
                <p class="hac_introduce">
                    Hello, this is JIEJOE, a visual designer
                    <br />Let's do some cool things :)
                </p>
                <svg class="hac_line" viewBox="0 0 1250 410">
                    <path
                        d="M65.7,212.4c0,0-31.4,169.6,20.9,163.3s51.3-141.3,59.7-141.3c8.3,0-18.3,163.6,25.4,141.5
	c43.8-22.1,77.2-200.1,41.5-183.4s-19.4,135,26.9,96.9c46.4-38.2,143.1-218.6,92.9-264.7c-71.1-60.8-51.9,366.7-51.9,366.7
	s45.5-217,79.2-178.3S328.1,385.7,395.1,371c67-14.7,121.2-55.5,108.3-104.6c-13-39.8-63.6-57-89.8-11.1s0.7,139.6,33.3,121.2
	c48.1-27.2,191.4-182.4,241.6-144.7c50.2,37.7-39.8,169.6-64.9,129.8c-25.1-39.8,29.3-146.6,75.3-121.4
	c46.1,25.2-9.6,128,35.6,136.1c26,6.8,41.3-161.2,41.3-161.2s2.1,146.6,8.4,146.6s31.4-209.4,54.4-154.9
	c23,54.4,14.6,142.4,23,144.4c8.4,2,27.2-159.2,77.4-146.6c39.8,10.5-7.5,187.9,57.5,171.1c65-16.8,116.5-177.5,116.5-177.5
	s-48.4,197,41.6,178.2c30.5-8.3,38.9-39.8,38.9-39.8"
                    />
                </svg>
            </div>
        </div>
        <div class="home_portrait">
            <div class="home_portrait_textline">
                <div></div>
                <p>portrait</p>
                <div></div>
                <p>portrait</p>
                <div></div>
            </div>
            <div class="home_portrait_main">
                <div class="hpm_imgbox">
                    <img :src="require('../assets/images/home/portrait_bone.svg')" alt="portrait_bone" />
                </div>
                <div class="hpm_imgbox hpm_imgbox_bottom">
                    <img :src="require('../assets/images/home/portrait.svg')" alt="portrait" />
                </div>
            </div>
            <div class="home_portrait_textline">
                <div></div>
                <p>portrait</p>
                <div></div>
                <p>portrait</p>
                <div></div>
            </div>
            <div class="home_portrait_line">
                <div></div>
            </div>
        </div>
        <div class="home_skills">
            <p class="home_skills_title">
                what
                <span>skills</span>
                <br />can i have
            </p>
            <div class="home_skills_skill">
                <div class="hss_text">
                    <p>i'm a</p>
                    <p>graphic</p>
                    <p>designer</p>
                    <p>爱做一些奇奇怪怪的设计</p>
                    <p>01</p>
                </div>
                <div class="hss_block">
                    <div class="hss_block_canvas"></div>
                </div>
            </div>
            <div class="home_skills_skill">
                <div class="hss_text">
                    <p>i'm a</p>
                    <p>video</p>
                    <p>editor</p>
                    <p>爱剪一些莫名其妙的视频</p>
                    <p>02</p>
                </div>
                <div class="hss_block">
                    <div class="hss_block_canvas"></div>
                </div>
            </div>
            <div class="home_skills_skill">
                <div class="hss_text">
                    <p>i'm a</p>
                    <p>UX</p>
                    <p>designer</p>
                    <p>爱做一些不拘一格的交互</p>
                    <p>03</p>
                </div>
                <div class="hss_block">
                    <div class="hss_block_canvas"></div>
                </div>
            </div>
        </div>
        <div class="home_idea">
            <div class="home_idea_background" @click="pages.idea.check_light($event)"></div>
            <div class="home_idea_container">
                <p>Full of creativity</p>
                <p>
                    Able to
                    <span>imagine</span>
                </p>
                <p>
                    <span>Ready</span>
                    to create
                </p>
                <img class="hic_horse" :src="require('../assets/images/home/horse.svg')" alt="horse" />
                <img class="hic_brain" :src="require('../assets/images/home/brain.svg')" alt="brain" />
            </div>
        </div>
        <div class="home_vision">
            <div class="home_vision_scrolllines" v-for="index in 4" :key="index">
                <div class="hvs_line" v-for="index in 3" :key="index">
                    <div v-for="index in 5" :key="index">
                        visual
                        <p>first</p>
                    </div>
                </div>
            </div>
            <div class="home_vision_block">
                <p>视</p>
                <p>觉</p>
                <p>至</p>
                <p>上</p>
            </div>
            <div id="home_vision_spidereye" />
        </div>
        <div class="home_works">
            <div class="home_works_title">
                <p v-for="(letter,index) in 'portfolio'" :key="index">{{letter}}</p>
            </div>
            <p class="home_works_zh">JIEJOE的剪辑和设计作品</p>
            <p class="home_works_eng">jiejoe's editing and design works</p>
            <div class="home_works_line"></div>
        </div>
        <div class="home_videotitle">
            <p class="home_videotitle_text">videos</p>
            <p class="home_videotitle_textline">videos</p>
            <div class="home_videotitle_frames">
                <div class="hvf_frame" v-for="line in 2" :key="line">
                    <div v-for="index in pages.video_title.frames_total" :key="index" class="hvf_frame_img">
                        <img :src="require(`../assets/images/home/video_title/video_title_${index-1}.webp`)" alt="frame" />
                    </div>
                </div>
            </div>
            <div class="home_videotitle_mousetip" v-if="pages.video_title.if_mousetip_render">
                <p>HOLD</p>
                <svg viewBox="0 0 50 50">
                    <circle class="home_videotitle_mousetip_circle" cx="25" cy="25" r="25" />
                </svg>
            </div>
        </div>
        <div class="home_videos">
            <div
                v-for="(video, index) in pages.videos.json_videos_data"
                :key="index"
                class="home_videos_video"
                @mouseenter="pages.videos.if_card_render&&pages.videos.change_photo(index)"
                @click="$router.push('videos')"
                :index="index"
            >
                <p class="hvv_nums">0{{index+1}}</p>
                <div class="hvv_information">
                    <p>{{ video.name_eng }}</p>
                    <p>+ {{ video.name_zh }} +</p>
                </div>
                <p class="hvv_time">{{ video.time }}</p>
            </div>
            <div class="home_videos_card" v-if="pages.videos.if_card_render">
                <img alt="cover" />
            </div>
        </div>
        <div class="home_photos">
            <div class="home_photos_box">
                <p class="hpb_title">PHOTOS</p>
                <div class="home_photos_plates">
                    <div class="hpp_plate" v-for="index in 5" :key="index" :class="`hpp_plate${index}`" @click="$router.push('photos')">
                        <img :src="`https://cdn.jiejoe.com/photos/photo_${5-index+1}.webp`" alt="plate" />
                    </div>
                    <div class="hpp_top hpp_plate5" @click="pages.photos.move_imgs()">
                        <svg class="hpp_top_arm" viewBox="0 0 300 715">
                            <g>
                                <image class="hpp_top_arm_hand" width="300" height="715" :href="require('../assets/images/home/arm_hand.svg')" />
                                <image width="300" height="715" :href="require('../assets/images/home/arm_sleeve.svg')" />
                            </g>
                        </svg>
                    </div>
                </div>
                <p class="home_photos_tip">get more photos of jiejoe</p>
                <p class="home_photos_time">
                    2019
                    <span>-</span>
                    2024
                </p>
            </div>
        </div>
        <div class="home_contact">
            <div class="home_contact_text">
                <div class="hct_title">contact me</div>
                <img :src="require('../assets/images/home/icon_email.svg')" alt="email" />
                <img :src="require('../assets/images/home/icon_address.svg')" alt="address" />
                <img :src="require('../assets/images/home/icon_qq.svg')" alt="qq" />
                <img :src="require('../assets/images/home/icon_wechat.svg')" alt="wechat" />
            </div>
            <div class="home_contact_phone"></div>
            <div class="home_contact_bottom"></div>
        </div>
        <div class="home_bottom">
            <div class="home_bottom_messages">
                <div class="hbm_message">
                    <img :src="'./QR/email.png'" alt="email" />
                    <p>
                        <span>Email</span>jie_joe@qq.com
                    </p>
                </div>
                <div class="hbm_message">
                    <img :src="'./QR/wechat.png'" alt="wechat" />
                    <p>
                        <span>WeChat</span>JIEJOE-Visual
                    </p>
                </div>
                <div class="hbm_message">
                    <img :src="'./QR/qq.png'" alt="qq" />
                    <p>
                        <span>QQ</span>3674786250
                    </p>
                </div>
            </div>
            <div class="home_bottom_bottom">
                <div class="hbb_menu">
                    <p @click="$router.push('home')">home</p>
                    <p @click="$router.push('videos')">videos</p>
                    <p @click="$router.push('photos')">photos</p>
                    <p @click="$router.push('contact')">contact</p>
                </div>
                <a class="hbb_link" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022022313号-2</a>
            </div>
        </div>
    </div>
</template>

<script>
import Scrollbar from "@/components/scrollbar.vue";
import gsap from "gsap";
import lottie from "lottie-web";
import {
    Engine,
    Render,
    Bodies,
    Body,
    Composite,
    Query,
    Events,
    Mouse,
    MouseConstraint,
    Constraint,
} from "matter-js";
export default {
    name: "home",
    components: {
        Scrollbar,
    },
    data() {
        return {
            pages: {
                // welcome首屏板块
                wel: {
                    // DOM对象
                    container: null,
                    background: null,
                    bigball: null,
                    smallball: null,
                    // lottie动画播放器
                    animation_player: null,
                    // 观察者
                    observer: null,
                    init() {
                        this.container = document.querySelector(".home_welcome");
                        this.background = document.querySelector(".home_welcome_background");
                        this.bigball = document.querySelector(".hwb_blurball1");
                        this.smallball = document.querySelector(".hwb_blurball2");
                        // 初始化lottie动画
                        this.animation_player = lottie.loadAnimation({
                            container: document.querySelector(".home_welcome_logo"),
                            renderer: "svg",
                            loop: true,
                            autoplay: true,
                            animationData: require("../assets/animations/logo_geometry.json"),
                        });
                        // 绑定光球:光球跟随鼠标小幅移动
                        this.container.ontouchmove = (e) => {
                            this.move_ball(e.touches[0].clientX, e.touches[0].clientY);
                        };
                        this.container.onmousemove = (e) => {
                            this.move_ball(e.x, e.y);
                        };
                        this.observe_container();
                    },
                    // 当板块进入视口时，播放lottie动画，反之则关闭，以提升性能
                    observe_container() {
                        this.observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting) this.animation_player.play();
                            else this.animation_player.pause();
                        });
                        this.observer.observe(this.container);
                    },
                    // 移动光球
                    move_ball(x, y) {
                        let distance = (x - innerWidth / 2) / (5 * (innerWidth / innerHeight)); //以屏幕中点计算左右横向移动距离
                        let angle = (y / innerHeight) * 30 - 15; //以竖向距离来计算旋转角度
                        gsap.timeline()
                            .to(this.background, {
                                rotate: `${angle}deg`,
                                duration: 3,
                                ease: "power3.out",
                            })
                            .to(
                                this.bigball,
                                {
                                    x: `${distance}px`,
                                    duration: 3,
                                    ease: "power3.out",
                                },
                                "<"
                            )
                            .to(
                                this.smallball,
                                {
                                    x: `${-distance}px`,
                                    duration: 3,
                                    ease: "power3.out",
                                },
                                "<"
                            );
                    },
                    // 初始化展示动画
                    show() {
                        gsap.timeline()
                            .to(".hwm_title div", {
                                opacity: 1,
                                x: 0,
                                duration: 1.2,
                                ease: "power4.out",
                                stagger: 0.1,
                            })
                            .to(
                                ".home_welcome_nobody",
                                {
                                    transform: `rotate(15deg) scale(1)`,
                                    duration: 0.8,
                                    ease: "power4.out",
                                },
                                "<0.2"
                            )
                            .set(
                                ".hwm_title div:nth-child(1) p",
                                {
                                    color: "var(--theme_white)",
                                    "-webkit-text-stroke": "none",
                                },
                                "<0.1"
                            )
                            .set(
                                ".hwm_title div:nth-child(2) p",
                                {
                                    color: "var(--theme_green)",
                                    "-webkit-text-stroke": "none",
                                },
                                "<0.1"
                            )
                            .set(
                                ".hwm_title div:nth-child(3) p",
                                {
                                    color: "var(--theme_white)",
                                    "-webkit-text-stroke": "none",
                                },
                                "<0.1"
                            )
                            .to(
                                ".home_welcome_scrolltip",
                                {
                                    opacity: 1,
                                    transform: "translateY(0)",
                                    duration: 1,
                                    ease: "power2.out",
                                },
                                "<"
                            );
                    },
                    remove() {
                        this.observer.unobserve(this.container);
                        this.observer = null;
                        this.animation_player.destroy();
                        this.animation_player = null;
                        this.container = null;
                        this.background = null;
                        this.bigball = null;
                        this.smallball = null;
                    },
                },
                // 个人介绍板块
                about: {
                    content: null,
                    observer: null,
                    init() {
                        this.content = document.querySelector(".home_about_content");
                        // 监听视口，触发动画
                        this.observer = new IntersectionObserver(
                            (eles) => {
                                if (eles[0].isIntersecting) {
                                    this.content.classList.add("home_about_content_show");
                                    this.observer.unobserve(this.content);
                                }
                            },
                            {
                                rootMargin: `${-innerHeight / 6}px 0px`,
                            }
                        );
                        this.observer.observe(this.content);
                    },
                    remove() {
                        this.observer.unobserve(this.content);
                        this.content = null;
                        this.observer = null;
                    },
                },
                // 肖像图片
                portrait: {
                    // DOM对象
                    container: null,
                    line: null,
                    imgbox: null,
                    imgbox_img: null,
                    main: null,
                    // 观察者
                    container_observer: null,
                    line_observer: null,
                    scroll_distance: 0,
                    trigger_distance: 0, //滚动监听的触发距离：进入则触发监听
                    edge_distance: 0, //滚动监听的边界距离：离开则移除监听
                    max_distance: 0, //光线能够移动到的最大距离范围：限制光线不会超出肖像
                    init() {
                        this.container = document.querySelector(".home_portrait");
                        this.line = document.querySelector(".home_portrait_line");
                        this.imgbox = document.querySelector(".hpm_imgbox_bottom");
                        this.imgbox_img = this.imgbox.querySelector("img");
                        this.main = document.querySelector(".home_portrait_main");
                        this.resize();
                        this.observe_container();
                        this.observe_line();
                    },
                    resize() {
                        this.trigger_distance = this.container.offsetTop - innerHeight * 0.5;
                        this.edge_distance = this.container.offsetHeight + innerHeight * 0.5;
                        this.max_distance = this.main.offsetHeight;
                    },
                    observe_container() {
                        this.container_observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting) {
                                this.create_listener();
                                this.reset();
                            } else this.remove_listener();
                        });
                        this.container_observer.observe(this.container);
                    },
                    create_listener() {
                        this.binded_move = this.move.bind(this);
                        window.addEventListener("scroll", this.binded_move);
                    },
                    remove_listener() {
                        window.removeEventListener("scroll", this.binded_move);
                    },
                    // 重置光线位置
                    reset() {
                        // 进入可视区，光线位置进行重置:从上进入，则回到顶端，从下进入，则回到底部
                        let distance = scrollY < this.trigger_distance ? 0 : this.max_distance;
                        gsap.timeline()
                            .set(this.line, {
                                y: `${distance}px`,
                            })
                            .set(this.imgbox, {
                                y: `${distance}px`,
                            })
                            .set(this.imgbox_img, {
                                y: `${-distance}px`,
                            });
                    },
                    // 移动光线
                    move() {
                        // 进入监听区域，不能马上触发移动，还需要进入光线的移动触发距离才行
                        if (scrollY < this.trigger_distance) return;
                        this.scroll_distance = scrollY - this.trigger_distance;
                        // 将滑动距离值映射转化为光线的移动距离
                        let distance =
                            (this.scroll_distance / this.edge_distance) * this.max_distance;
                        // 限制移动边界
                        distance = Math.min(this.max_distance, distance);
                        distance = Math.max(0, distance);
                        // 移动光线以及图片
                        gsap.timeline()
                            .set(this.line, {
                                y: `${distance}px`,
                            })
                            .set(this.imgbox, {
                                y: `${distance}px`,
                            })
                            .set(this.imgbox_img, {
                                y: `${-distance}px`,
                            });
                    },
                    // 观察扫描光线：光线进入的时候会有一个展开动画
                    observe_line() {
                        this.line_observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting) {
                                this.line.classList.add("home_portrait_line_show");
                                this.line_observer.unobserve(this.line);
                            }
                        });
                        this.line_observer.observe(this.line);
                    },
                    remove() {
                        this.container_observer.unobserve(this.container);
                        this.line_observer.unobserve(this.line);
                        window.removeEventListener("scroll", this.binded_move);
                        this.container = null;
                        this.line = null;
                        this.imgbox = null;
                        this.imgbox_img = null;
                        this.main = null;
                        this.container_observer = null;
                        this.line_observer = null;
                    },
                },
                // 技能板块
                skills: {
                    // DOM元素
                    container: null,
                    title: null,
                    skills: null,
                    // 观察者
                    container_observer: {},
                    title_observer: {},
                    skills_observer: {},
                    // lottie动画播放对象
                    animation_players: [],
                    // 动画播放数据
                    animation_data: [
                        require("../assets/animations/panel_ps.json"),
                        require("../assets/animations/panel_pr.json"),
                        require("../assets/animations/panel_edg.json"),
                    ],
                    init() {
                        this.container = document.querySelector(".home_skills");
                        this.title = document.querySelector(".home_skills_title");
                        this.skills = [...document.querySelectorAll(".home_skills_skill")];
                        // 初始化lottie动画
                        const canvas = [...document.querySelectorAll(".hss_block_canvas")];
                        for (let i = 0; i < this.animation_data.length; i++) {
                            this.animation_players.push(
                                lottie.loadAnimation({
                                    container: canvas[i],
                                    renderer: "svg",
                                    loop: true,
                                    autoplay: false,
                                    animationData: this.animation_data[i],
                                })
                            );
                        }
                        this.observe_container();
                        this.observe_title();
                        this.observe_skills();
                    },
                    // 观察技能板块：当其进入窗口时才播放lottie动画，以节省性能
                    observe_container() {
                        this.container_observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting)
                                this.animation_players.forEach((player) => {
                                    player.play();
                                });
                            else
                                this.animation_players.forEach((player) => {
                                    player.pause();
                                });
                        });
                        this.container_observer.observe(this.container);
                    },
                    // 观察标题：当其进入视口时，播放问号显示动画
                    observe_title() {
                        this.title_observer = new IntersectionObserver(
                            (eles) => {
                                if (eles[0].isIntersecting) {
                                    this.title.classList.add("home_skills_title_show");
                                    this.title_observer.unobserve(this.title);
                                }
                            },
                            {
                                rootMargin: `${-innerHeight / 4}px 0px`,
                            }
                        );
                        this.title_observer.observe(this.title);
                    },
                    // 观察技能文字：当其进入视口时，播放文字动画
                    observe_skills() {
                        this.skills_observer = new IntersectionObserver(
                            (eles) => {
                                eles.forEach((ele) => {
                                    if (ele.isIntersecting) {
                                        gsap.timeline()
                                            .to(
                                                ele.target.querySelector(
                                                    ".hss_text p:nth-child(4)"
                                                ),
                                                {
                                                    opacity: 1,
                                                    duration: 1.5,
                                                    ease: "power4.out",
                                                }
                                            )
                                            .to(
                                                ele.target.querySelector(
                                                    ".hss_text p:nth-child(1)"
                                                ),
                                                {
                                                    transform: " translateX(0%)",
                                                    opacity: 1,
                                                    duration: 0.3,
                                                    ease: "power4.out",
                                                },
                                                "<"
                                            )
                                            .to(
                                                ele.target.querySelector(
                                                    ".hss_text p:nth-child(2)"
                                                ),
                                                {
                                                    transform: "scaleY(0.8) translateY(0)",
                                                    opacity: 1,
                                                    duration: 0.8,
                                                    ease: "power4.out",
                                                },
                                                "<0.1"
                                            )
                                            .to(
                                                ele.target.querySelector(
                                                    ".hss_text p:nth-child(3)"
                                                ),
                                                {
                                                    transform: "scaleY(0.8) translateY(0%)",
                                                    opacity: 1,
                                                    duration: 1,
                                                    ease: "power4.out",
                                                },
                                                "<0.1"
                                            )
                                            .set(
                                                ele.target.querySelector(
                                                    ".hss_text p:nth-child(3)"
                                                ),
                                                {
                                                    "-webkit-text-stroke": "none",
                                                    color: "var(--theme_green)",
                                                },
                                                "<0.15"
                                            );
                                        this.skills_observer.unobserve(ele.target);
                                    }
                                });
                            },
                            {
                                rootMargin: `${-innerHeight / 4}px 0px`,
                            }
                        );
                        this.skills.forEach((skill) => {
                            this.skills_observer.observe(skill);
                        });
                    },
                    remove() {
                        this.container_observer.unobserve(this.container);
                        this.title_observer.unobserve(this.title);
                        this.skills.forEach((skill) => {
                            this.skills_observer.unobserve(skill);
                        });
                        this.container = null;
                        this.title = null;
                        this.skills = null;
                        this.container_observer = null;
                        this.title_observer = null;
                        this.skills_observer = null;
                        this.animation_players.forEach((player) => {
                            player.destroy();
                            player = null;
                        });
                        this.animation_players = null;
                        this.animation_data = null;
                    },
                },
                // 想法板块
                idea: {
                    container: null,
                    images: null,
                    observer: null, //观察者
                    scroll_distance: 0, //竖向滑动距离
                    trigger_distance: 0, //触发滑动距离
                    max_distance: 0, //最大边界距离
                    // 灯泡
                    image_lampbreak: require("../assets/images/home/lamp_break.svg"), //破碎灯泡图片
                    image_lamplight: require("../assets/images/home/lamp_light.svg"), //完整点亮灯泡图片
                    if_movable: false, // 是否可以控制灯泡移动
                    sleep_timer: null, // 用于定时检查灯泡是否停止运动
                    scale_nums: 0, // 灯泡的大小缩放比例值
                    force: 0, // 用于施加在灯泡上的力大小
                    lampbreak_nums: 3, // 当前被打破的灯泡数
                    // 物理引擎渲染参数
                    background: null,
                    width: 0,
                    height: 0,
                    engine: null,
                    render: null,
                    if_run_engine: false, //是否已运行物理引擎
                    lights: null, // 所有的灯泡物体
                    if_first_trigger: true, // 是否是初次触发引擎
                    last_scroll_y: 0, //上一次的滑动纵向y距离:用于判断滑动方向
                    // 上一次resize时的窗口宽高
                    last_width: innerWidth,
                    last_height: innerHeight,
                    init() {
                        this.container = document.querySelector(".home_idea");
                        this.images = document.querySelectorAll(".home_idea_container img");
                        this.background = document.querySelector(".home_idea_background");
                        this.resize();
                        this.creat_engine();
                        this.observe_container();
                    },
                    resize() {
                        this.trigger_distance = this.container.offsetTop - innerHeight;
                        this.max_distance = this.container.offsetHeight;
                        this.width = this.background.offsetWidth;
                        this.height = this.background.offsetHeight;
                        // 计算灯泡的缩放比例值，由窗口的宽高共同决定
                        // 1000宽度,1000高度下，灯泡的标准大小为0.45
                        // 1000宽度,500高度下，灯泡的标准大小为0.4
                        // 500宽度,1000高度下，灯泡的标准大小为0.35
                        // 比例公式：灯泡大小=0.45+0.3×(宽度比例−1)+0.1×(高度比例−1)
                        this.scale_nums =
                            0.45 + 0.3 * (innerWidth / 1000 - 1) + 0.1 * (innerHeight / 1000 - 1);
                        // 检测如果是safari浏览器：则再缩小1.8倍缩放系数，否则在safari上会出现物体过大的BUG
                        if (this.if_safari) this.scale_nums = this.scale_nums / 1.8;
                        // 重新计算作用到灯泡的力大小
                        // 400宽度,1000高度,0.01
                        // 600宽度,1000高度,0.015
                        // 800宽度,1000高度,0.018
                        // 1000宽度,1000高度,0.018
                        // 1500宽度,1000高度,0.03
                        // 2000宽度,1000高度,0.06
                        // 1000宽度,400高度,0.015
                        // 1000宽度,600高度,0.02
                        // 1000宽度,800高度,0.02
                        // 1000宽度,1000高度,0.02
                        // 1000宽度,1500高度,0.03
                        // 1000宽度,2000高度,0.04
                        this.force = 2.41e-8 * (innerWidth * innerHeight) + 7.96e-5;
                        // 如果横纵改变的值不大，则不重新渲染灯泡，因为移动端容易出性能bug
                        //这里的变化对高度不敏感主要是为了解决移动端的自带导航栏影响页面高度
                        if (
                            Math.abs(this.last_width - innerWidth) > innerWidth / 15 ||
                            Math.abs(this.last_height - innerHeight) > innerHeight / 6
                        ) {
                            // 灯泡重新生成，更新灯泡参数
                            this.if_first_trigger = true;
                            this.if_movable = false;
                            this.lampbreak_nums = 3;
                            // 更新渲染器的宽高
                            if (this.render) {
                                this.render.canvas.width = this.width;
                                this.render.canvas.height = this.height;
                            }
                            // 重新将物体加入到世界
                            if (this.lights) this.updata_box();
                            // 更新上一次的宽高
                            this.last_width = innerWidth;
                            this.last_height = innerHeight;
                        }
                    },
                    // 创建引擎和渲染器
                    creat_engine() {
                        this.engine = Engine.create({
                            constraintIterations: 1,
                        });
                        this.render = Render.create({
                            element: this.background,
                            engine: this.engine,
                            options: {
                                width: this.width,
                                height: this.height,
                                background: `#171717`,
                                wireframes: false,
                            },
                        });
                        // 将物体加入到世界
                        this.updata_box();
                    },
                    // 将物体加入到世界
                    updata_box() {
                        // 清空世界里的所有物体
                        this.lights = [];
                        Composite.clear(this.engine.world);
                        // 创建格挡，及上下左右四个长方体，将灯泡全部局限在里面，防止灯泡掉出世界
                        let ground_top = Bodies.rectangle(this.width / 2, -15, this.width, 30, {
                            isStatic: true,
                            render: {
                                fillStyle: "#171717",
                                lineWidth: 0,
                            },
                        });
                        let ground_bottom = Bodies.rectangle(
                            this.width / 2,
                            this.height + 15,
                            this.width,
                            30,
                            {
                                isStatic: true,
                                render: {
                                    fillStyle: "#171717",
                                    lineWidth: 0,
                                },
                            }
                        );
                        let ground_left = Bodies.rectangle(
                            0 - 15,
                            this.height / 2,
                            30,
                            this.height,
                            {
                                isStatic: true,
                                render: {
                                    fillStyle: "#171717",
                                    lineWidth: 0,
                                },
                            }
                        );
                        let ground_right = Bodies.rectangle(
                            this.width + 15,
                            this.height / 2,
                            30,
                            this.height,
                            {
                                isStatic: true,
                                render: {
                                    fillStyle: "#171717",
                                    lineWidth: 0,
                                },
                            }
                        );
                        // 循环生成灯泡
                        for (let i = 0; i < 20; i++) {
                            // 设置几个灯泡为破碎的，可以避免加载纹理从而出现灯泡点击的时候闪烁
                            let texture =
                                i > this.lampbreak_nums - 1
                                    ? this.image_lamplight
                                    : this.image_lampbreak;
                            let light = Bodies.fromVertices(
                                //设置灯泡出现在屏幕上方随机位置
                                Math.random() * this.width,
                                (Math.random() * this.height) / 2,
                                // 以下数据用来生成一个近似于灯泡形状的多边形，并乘上缩放比例值
                                [
                                    {
                                        x: 140 * this.scale_nums,
                                        y: 29 * this.scale_nums,
                                    },
                                    {
                                        x: 194 * this.scale_nums,
                                        y: 113 * this.scale_nums,
                                    },
                                    {
                                        x: 134 * this.scale_nums,
                                        y: 309 * this.scale_nums,
                                    },
                                    {
                                        x: 114 * this.scale_nums,
                                        y: 334 * this.scale_nums,
                                    },
                                    {
                                        x: 88 * this.scale_nums,
                                        y: 334 * this.scale_nums,
                                    },
                                    {
                                        x: 67 * this.scale_nums,
                                        y: 309 * this.scale_nums,
                                    },
                                    {
                                        x: 9 * this.scale_nums,
                                        y: 113 * this.scale_nums,
                                    },
                                    {
                                        x: 62 * this.scale_nums,
                                        y: 29 * this.scale_nums,
                                    },
                                ],
                                {
                                    render: {
                                        sprite: {
                                            texture, // 加入灯泡纹理
                                            xScale: 2.25 * this.scale_nums,
                                            yScale: 2.25 * this.scale_nums,
                                        },
                                    },
                                }
                            );
                            light.if_break = i > this.lampbreak_nums - 1 ? false : true; // 为灯泡添加属性，判断是否被打碎
                            Body.setAngle(light, Math.PI / Math.random()); //设置随机旋转角度
                            this.lights.push(light);
                        }
                        // 将所有物体加入到world
                        Composite.add(this.engine.world, [
                            ground_top,
                            ground_bottom,
                            ground_left,
                            ground_right,
                            ...this.lights,
                        ]);
                    },
                    // 观察容器，当该板块进入视口，添加监听，反正则取消
                    observe_container() {
                        this.observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting) this.create_listener();
                            else this.remove_listener();
                        });
                        this.observer.observe(this.container);
                    },
                    // 创建监听
                    create_listener() {
                        // 启动runner，开始更新engine
                        Render.run(this.render);
                        this.if_run_engine = true;
                        this.update_engine();
                        // 绑定监听事件
                        this.binded_move_eles = this.move_eles.bind(this);
                        this.binded_move_lights = this.move_lights.bind(this);
                        window.addEventListener("scroll", this.binded_move_eles);
                        window.addEventListener("scroll", this.binded_move_lights);
                    },
                    // 取消监听
                    remove_listener() {
                        // 停止runner和engine
                        Render.stop(this.render);
                        this.if_run_engine = false;
                        window.removeEventListener("scroll", this.binded_move_eles);
                        window.removeEventListener("scroll", this.binded_move_lights);
                    },
                    // 更新引擎
                    update_engine() {
                        if (!this.if_run_engine) return;
                        // 此处引擎的更新为手动,如果直接run引擎的话,则没法关闭
                        Engine.update(this.engine);
                        requestAnimationFrame(this.update_engine.bind(this));
                    },
                    // 滑动控制移动灯泡：在滑动时，模拟上下的力来推动灯泡
                    move_lights() {
                        // 判断是否是首次触发
                        if (this.if_first_trigger) {
                            this.if_first_trigger = false;
                            // 如果为首次触发,灯泡加入到世界后,会先一起向下掉落,需要等所有灯泡停止大幅度运动后,才可以控制灯泡
                            this.sleep_timer = setInterval(() => {
                                let all_sleeping = true;
                                // 判断灯泡的速度来确认灯泡是否静止
                                for (let body of Composite.allBodies(this.engine.world)) {
                                    if (body.speed > 1) {
                                        all_sleeping = false;
                                        break;
                                    }
                                }
                                // 所有灯泡都静止后,才可以移动灯泡
                                if (all_sleeping) {
                                    this.if_movable = true;
                                    clearInterval(this.sleep_timer);
                                }
                            }, 1000);
                        }
                        // 计算本次滑动的方向
                        let dirction = scrollY - this.last_scroll_y > 0 ? 1 : -1;
                        if (this.if_movable)
                            // 给灯泡一个对应Y方向的力,控制灯泡移动
                            this.lights.forEach((box) => {
                                Body.applyForce(box, box.position, {
                                    x: 0,
                                    y: -(Math.random() * this.force) * dirction,
                                });
                            });
                        this.last_scroll_y = scrollY; // 更新last_scroll_y
                    },
                    // 点击灯泡,判断灯泡是否击碎
                    check_light(e) {
                        if (this.lampbreak_nums == this.lights.length) return;
                        let offset = this.background.getBoundingClientRect().top;
                        // 查询鼠标点击位置处的灯泡
                        const selected_lamp = [
                            ...Query.point(Composite.allBodies(this.engine.world), {
                                x: e.x,
                                y: e.y - offset,
                            }),
                        ][0];
                        if (!selected_lamp || selected_lamp.if_break) return;
                        // 如果该位置的灯泡存在,且没有被击碎，则更换灯泡为破碎的纹理,表示灯泡击碎,并修改对应属性
                        selected_lamp.render.sprite.texture = this.image_lampbreak;
                        selected_lamp.if_break = true;
                        this.lampbreak_nums++;
                        // 当所有灯泡都被击碎后,触发界面灰色效果
                        if (this.lampbreak_nums == this.lights.length)
                            document.querySelector("html").classList.add("gray");
                    },
                    // 移动模块上的两个图片元素
                    move_eles() {
                        this.scroll_distance = scrollY - this.trigger_distance;
                        // 限制滑动距离大小
                        this.scroll_distance = Math.max(0, this.scroll_distance);
                        this.scroll_distance = Math.min(this.max_distance, this.scroll_distance);
                        // 移动图片元素，制造视差效果
                        gsap.to(this.images, {
                            y: `${this.scroll_distance / 10}px`,
                            duration: 0.5,
                            ease: "linear",
                        });
                    },
                    // 清除引擎
                    clear_engine() {
                        clearInterval(this.sleep_timer);
                        this.sleep_timer = null;
                        this.if_run_engine = false;
                        Engine.clear(this.engine);
                        Render.stop(this.render);
                        this.render.canvas.remove();
                        Composite.clear(this.engine.world);
                        this.background = null;
                        this.engine = null;
                        this.render = null;
                        this.lights = null;
                    },
                    remove() {
                        this.clear_engine();
                        this.observer.unobserve(this.container);
                        this.observer = null;
                        this.container = null;
                        this.images = null;
                        this.image_lampbreak = null;
                        this.image_lamplight = null;
                        window.removeEventListener("scroll", this.binded_move_eles);
                        window.removeEventListener("scroll", this.binded_move_lights);
                    },
                },
                // 蜘蛛眼板块
                spider_eye: {
                    // DOM对象
                    container: null,
                    spider: null, // 蜘蛛眼对象
                    block: null, // 背景块
                    //观察者对象
                    observer: null,
                    scale_nums: 0, // 背景块的缩放倍数值
                    scroll_distance: 0,
                    trigger_distance: 0,
                    max_distance: 0,
                    animation_player: null, // lottie动画播放器
                    spider_runner: null, // 蜘蛛动画启动器
                    frames_player: null, // 帧动画播放器
                    // 动画的最大帧数
                    max_frame: 0,
                    current_frame: 0, // 动画的当前帧数
                    target_frame: 0, // 动画播放的目标帧数
                    // 每一帧的间隔旋转角度
                    interval_angle: 0,
                    last_angle: 0, //上一次的旋转角度：用于使蜘蛛眼旋转更顺滑，防止蜘蛛眼反转
                    init() {
                        this.container = document.querySelector(".home_vision");
                        this.spider = document.getElementById("home_vision_spidereye");
                        this.block = document.querySelector(".home_vision_block");
                        // 初始化蜘蛛眼Lottie动画
                        this.animation_player = lottie.loadAnimation({
                            container: this.spider,
                            renderer: "svg",
                            loop: false,
                            autoplay: true, //这里如果不设置自动播放让动画加载完成后播放一次的话，动画不会显示
                            animationData: require("../assets/animations/spider_eye.json"),
                        });
                        this.max_frame = this.animation_player.totalFrames;
                        this.interval_angle = 360 / this.max_frame;
                        this.resize();
                        this.observe_container();
                        // 使背景块填充满整个屏幕
                        gsap.set(this.block, {
                            scale: this.scale_nums,
                        });
                    },
                    resize() {
                        this.trigger_distance = this.container.offsetTop - innerHeight;
                        this.max_distance = this.container.offsetHeight;
                        // 计算眼球背景块的缩放值，使背景块正好可以填充满整个屏幕宽度
                        this.scale_nums = innerWidth / this.block.offsetWidth;
                    },
                    // 观察容器：进入视图时，添加监听，蜘蛛跟随鼠标转动，反之取消监听
                    observe_container() {
                        this.observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting) {
                                this.create_listener();
                                this.reset_block();
                            } else this.remove_listener();
                        });
                        this.observer.observe(this.container);
                    },
                    // 重置眼球背景块缩放
                    reset_block() {
                        // 向下滑动离开可视区，则背景块缩放为正常，反之，则背景块缩放为填充满屏幕
                        if (scrollY > this.trigger_distance)
                            gsap.set(this.block, {
                                scale: 1,
                            });
                        else
                            gsap.set(this.block, {
                                scale: this.scale_nums,
                            });
                    },
                    create_listener() {
                        this.binded_rotate_eye = this.rotate_eye.bind(this);
                        this.binded_scale_block = this.scale_block.bind(this);
                        window.addEventListener("touchmove", this.binded_rotate_eye);
                        window.addEventListener("mousemove", this.binded_rotate_eye);
                        window.addEventListener("scroll", this.binded_scale_block);
                    },
                    remove_listener() {
                        window.removeEventListener("touchmove", this.binded_rotate_eye);
                        window.removeEventListener("mousemove", this.binded_rotate_eye);
                        window.removeEventListener("scroll", this.binded_scale_block);
                    },
                    // 缩放背景块
                    scale_block() {
                        // 将滑动距离转化为缩放值
                        this.scroll_distance = scrollY - this.trigger_distance;
                        let nums =
                            (this.scroll_distance / this.max_distance) * (this.scale_nums - 1);
                        // 限制边界距离
                        nums = Math.max(0, nums);
                        nums = Math.min(this.scale_nums - 1, nums);
                        gsap.set(this.block, {
                            scale: this.scale_nums - nums,
                        });
                    },
                    // 旋转眼球
                    rotate_eye(e) {
                        if (e.type == "touchmove") {
                            e.x = e.touches[0].clientX;
                            e.y = e.touches[0].clientY;
                        }
                        //每次进来清除定时器，防止重复触发
                        clearTimeout(this.spider_runner);
                        clearInterval(this.frames_player);
                        let slope = (e.x - innerWidth / 2) / (e.y - innerHeight / 2); //计算斜率
                        // 计算旋转角度
                        let rotate_angle;
                        if (e.y >= innerHeight / 2)
                            rotate_angle = (Math.atan(slope) * 180) / Math.PI + 90;
                        else rotate_angle = 360 - 90 + (Math.atan(slope) * 180) / Math.PI;
                        // 将旋转角度转化为蜘蛛播放到的对应帧
                        this.target_frame = parseInt((360 - rotate_angle) / this.interval_angle);
                        this.target_frame = this.target_frame % (this.max_frame + 1);
                        // 计算本次和上次的角度间隔，保证旋转角度连续
                        let base_angle = this.last_angle % 360;
                        if (base_angle < 0) base_angle += 360;
                        let diff = rotate_angle - base_angle;
                        if (diff >= 180) diff -= 360;
                        else if (diff < -180) diff += 360;
                        rotate_angle = this.last_angle + diff;
                        // 旋转眼球
                        gsap.to(this.spider, {
                            rotate: `${-rotate_angle + 20}deg`,
                            duration: 1,
                            ease: "power4.out",
                        });
                        this.last_angle = rotate_angle; //更新last_angle
                        // 眼球旋转结束后，延迟100毫秒播放蜘蛛动画
                        this.spider_runner = setTimeout(() => {
                            this.frames_player = setInterval(this.move_spider.bind(this), 20);
                        }, 100);
                    },
                    // 播放蜘蛛帧动画:移动蜘蛛
                    move_spider() {
                        // 播放到指定帧则结束播放
                        if (this.current_frame == this.target_frame)
                            return clearInterval(this.frames_player);
                        // 判断旋转方向
                        let direction = this.current_frame >= this.target_frame ? -1 : 1;
                        this.current_frame += direction;
                        // 限定帧范围
                        if (this.current_frame > this.max_frame || this.current_frame < 0)
                            this.current_frame = 0;
                        // 控制lottie动画到指定帧
                        this.animation_player.goToAndStop(this.current_frame, true);
                    },
                    remove() {
                        window.removeEventListener("touchmove", this.binded_rotate_eye);
                        window.removeEventListener("mousemove", this.binded_rotate_eye);
                        window.removeEventListener("scroll", this.binded_scale_block);
                        clearTimeout(this.spider_runner);
                        clearInterval(this.frames_player);
                        this.animation_player.destroy();
                        this.observer.unobserve(this.container);
                        this.spider_runner = null;
                        this.frames_player = null;
                        this.animation_player = null;
                        this.observer = null;
                        this.container = null;
                        this.spider = null;
                        this.block = null;
                    },
                },
                // 作品集板块
                works: {
                    container: null,
                    observer: null, //观察者
                    init() {
                        this.container = document.querySelector(".home_works");
                        // 当该板块进入视图时触发动画
                        this.observer = new IntersectionObserver(
                            (eles) => {
                                if (eles[0].isIntersecting) {
                                    gsap.timeline()
                                        .to(".home_works_title p", {
                                            opacity: 1,
                                            transform: "translateY(0%)",
                                            duration: 0.5,
                                            ease: "power4.out",
                                            stagger: {
                                                each: 0.05,
                                                from: "random",
                                            },
                                        })
                                        .set(
                                            ".home_works_title p",
                                            {
                                                color: "var(--theme_white)",
                                                "-webkit-text-stroke": "unset",
                                                stagger: {
                                                    each: 0.05,
                                                    from: "random",
                                                },
                                            },
                                            "<0.2"
                                        );
                                    this.observer.unobserve(this.container);
                                }
                            },
                            {
                                rootMargin: `${-innerHeight / 3}px 0px`,
                            }
                        );
                        this.observer.observe(this.container);
                    },
                    remove() {
                        this.observer.unobserve(this.container);
                        this.observer = null;
                        this.container = null;
                    },
                },
                // 视频标题板块
                video_title: {
                    // DOM对象
                    container: null,
                    frames: null,
                    text: null,
                    // 鼠标提示
                    mousetip: null,
                    mousetip_circle: null,
                    if_mousetip_render: true, // 是否渲染mousetip
                    observer: null, //观察者
                    // 胶片属性
                    frames_total: 25, //胶片的总数
                    player: null, // 胶片的gsap动画播放对象
                    current_speed: 7, // 当前的动画播放速度
                    // 这里的最大最小速度所对应的是动画的间隔播放时间，时间越短速度越快
                    max_speed: 0.8, // 动画最大播放速度：
                    min_speed: 7, // 动画最小播放速度
                    add_speed: 0.05, // 动画的间隔增加速度
                    // 胶片是否加速播放
                    if_accelerating: false,
                    if_changable: false, // 是否可以改变胶片速度
                    init() {
                        this.container = document.querySelector(".home_videotitle");
                        this.frames = document.querySelector(".home_videotitle_frames");
                        this.text = document.querySelector(".home_videotitle_text");
                        // 初始化胶片的动画
                        this.player = gsap.to(".hvf_frame", {
                            x: "-100%",
                            duration: this.current_speed,
                            ease: "linear",
                            repeat: -1,
                            paused: true,
                        });
                        this.set_max_speed();
                        this.observe_container();
                        this.create_frames_events();
                        // 如果屏幕触点不止一个，则判断为移动端手势，不渲染mousetip
                        if (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
                            return (this.if_mousetip_render = false);
                        this.create_mousetip_events();
                    },
                    // 设置动画的最大播放速度
                    set_max_speed() {
                        let frame_rat = 0; //设置当前浏览器系统帧率
                        let start_time = performance.now();
                        // 每一帧更新frame的帧率
                        const undata = () => {
                            frame_rat++;
                            // 一秒后停止，得到当前系统大概的帧率
                            if (performance.now() - start_time > 1000) {
                                // 因为这里计算出来的帧率不一定准确，所以手动配置成常见帧率
                                if (0 < frame_rat && frame_rat <= 35) frame_rat = 30;
                                else if (35 < frame_rat && frame_rat <= 65) frame_rat = 60;
                                else if (65 < frame_rat && frame_rat <= 80) frame_rat = 75;
                                else if (80 < frame_rat && frame_rat <= 125) frame_rat = 120;
                                else if (125 < frame_rat && frame_rat <= 185) frame_rat = 180;
                                else if (185 < frame_rat) frame_rat = 200;
                                this.max_speed = (frame_rat / 60) * 0.8; // 计算最大动画速度：60帧下的速度为0.8
                            } else requestAnimationFrame(undata);
                        };
                        undata();
                    },
                    // 观察板块，进入视图才播放胶片动画
                    observe_container() {
                        this.observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting) this.player.play();
                            else this.player.pause();
                        });
                        this.observer.observe(this.container);
                    },
                    // 为胶片绑定监听事件
                    create_frames_events() {
                        // 鼠标点击/手指按下，胶片移动速度加快
                        this.frames.onmousedown = this.frames.ontouchstart = () => {
                            this.if_accelerating = true;
                            if (this.if_changable) return;
                            this.if_changable = true; // 用if_changable避免重复开启加速
                            this.change_speed();
                        };
                        // 鼠标弹起,离开/手指离开，胶片移动速度变慢恢复
                        this.frames.onmouseup =
                            this.frames.onmouseleave =
                            this.frames.ontouchend =
                                () => (this.if_accelerating = false);
                    },
                    // 更改胶片的移动速度
                    change_speed() {
                        if (!this.if_changable) return;
                        // 加速
                        if (this.if_accelerating)
                            this.current_speed = Math.max(
                                this.max_speed,
                                this.current_speed - this.add_speed
                            );
                        // 减速
                        else
                            this.current_speed = Math.min(
                                this.min_speed,
                                this.current_speed + this.add_speed
                            );

                        // 如果速度到达最大速度或最小速度，则停止改变速度
                        if (
                            this.current_speed <= this.max_speed ||
                            this.current_speed >= this.min_speed
                        )
                            this.if_changable = false;
                        // 达到最大速度，关闭改变方法，不可再改变速度
                        if (this.current_speed <= this.max_speed) this.close_change();
                        // 重置胶片移动速度
                        this.player.duration(this.current_speed);
                        // 更改提示框描边效果和文字填充效果
                        let offset =
                            (this.current_speed - this.max_speed) /
                            (this.min_speed - this.max_speed);
                        this.text.style.clipPath = `polygon(0% 0%, ${100 - offset * 100}% 0%, ${
                            100 - offset * 100
                        }% 100%, 0% 100%)`;
                        if (this.if_mousetip_render)
                            this.mousetip_circle.style.strokeDashoffset = `${offset * 160}`;
                        requestAnimationFrame(() => {
                            this.change_speed();
                        });
                    },
                    // 关闭胶片速度改变
                    close_change() {
                        // 取消所有container的相关事件
                        this.frames.onmousedown =
                            this.frames.ontouchstart =
                            this.frames.onmouseup =
                            this.frames.onmouseleave =
                            this.frames.ontouchend =
                            this.container.onmousemove =
                            this.container.onmouseenter =
                            this.container.onmouseleave =
                                null;
                        // 隐藏mousetip
                        gsap.to(this.mousetip, {
                            scale: 0,
                            duration: 0.5,
                            ease: "power4.out",
                        });
                    },
                    // 为mousetip绑定监听事件
                    create_mousetip_events() {
                        this.mousetip = document.querySelector(".home_videotitle_mousetip");
                        this.mousetip_circle = document.querySelector(
                            ".home_videotitle_mousetip_circle"
                        );
                        this.container.onmousemove = (e) => {
                            gsap.to(this.mousetip, {
                                x: `${e.x}px`,
                                y: `${e.y}px`,
                                duration: 0.5,
                                ease: "power2.out",
                            });
                        };
                        this.container.onmouseenter = (e) => {
                            gsap.timeline()
                                .set(this.mousetip, {
                                    x: `${e.x}px`,
                                    y: `${e.y}px`,
                                })
                                .to(this.mousetip, {
                                    scale: 1,
                                    duration: 0.5,
                                    ease: "power4.out",
                                });
                        };
                        this.container.onmouseleave = () => {
                            gsap.to(this.mousetip, {
                                scale: 0,
                                duration: 0.5,
                                ease: "power4.out",
                            });
                        };
                    },
                    remove() {
                        this.if_changable = false;
                        this.observer.unobserve(this.container);
                        this.observer = null;
                        this.player.kill();
                        this.player = null;
                        this.container = null;
                        this.frames = null;
                        this.text = null;
                        this.mousetip = null;
                        this.mousetip_circle = null;
                    },
                },
                // 视频板块
                videos: {
                    // DOM对象
                    container: null,
                    card: null,
                    img: null,
                    // 用于展示的所有视频封面图片
                    imgs: [],
                    // 视频数据：倒置数组按时间取最近的前六条视频
                    json_videos_data: require("../assets/json/videos_data.json").slice(0, 6),
                    // 是否渲染card
                    if_card_render: true,
                    init() {
                        this.container = document.querySelector(".home_videos");
                        // 如果屏幕触点不止一个，则判断为移动端手势，不渲染card,后续事件不执行
                        if (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
                            return (this.if_card_render = false);
                        this.create_events();
                        this.card = document.querySelector(".home_videos_card");
                        this.img = document.querySelector(".home_videos_card img");
                        // 将视频封面加入到imgs中
                        this.json_videos_data.forEach((data) => {
                            let img = new Image();
                            img.src = `https://cdn.jiejoe.com/covers/${data.imgurl}`;
                            this.imgs.push(img);
                        });
                    },
                    // 绑定卡片事件
                    create_events() {
                        // 鼠标进入：显示卡片
                        this.container.onmouseenter = (e) => {
                            gsap.timeline()
                                .set(this.card, {
                                    x: `${e.x}px`,
                                    y: `${e.y}px`,
                                })
                                .to(this.card, {
                                    scale: 1,
                                    rotate: "-15deg",
                                    duration: 0.5,
                                    ease: "power4.out",
                                });
                        };
                        // 鼠标离开：隐藏卡片
                        this.container.onmouseleave = () => {
                            gsap.to(this.card, {
                                scale: 0,
                                rotate: "30deg",
                                duration: 0.5,
                                ease: "power4.out",
                            });
                        };
                        // 鼠标移动：移动卡片
                        this.container.onmousemove = (e) => {
                            gsap.to(this.card, {
                                x: `${e.x}px`,
                                y: `${e.y}px`,
                                duration: 0.5,
                                ease: "power2.out",
                            });
                        };
                    },
                    // 改变卡片中的图片
                    change_photo(index) {
                        this.img.src = ""; //防止网络不好：导致图片显示延迟
                        this.img.src = this.imgs[index].src;
                        gsap.fromTo(
                            this.img,
                            { rotate: "30deg", scale: 1.3 },
                            {
                                rotate: 0,
                                scale: 1,
                                duration: 0.5,
                                ease: "power4.out",
                            }
                        );
                    },
                    remove() {
                        this.container = null;
                        this.card = null;
                        this.img = null;
                        this.imgs = null;
                        this.json_videos_data = null;
                    },
                },
                // 图片板块
                photos: {
                    plates: null, //DOM对象
                    imgs: [], //所有的图片
                    img_length: 10, //图片的总数
                    img_index: 0, //当前的img序号
                    //transform数据：用于控制扑克牌位移
                    transform_datas: [
                        "rotate(-10deg)",
                        "rotate(-6deg) translate(35%, -12%)",
                        "rotate(-2deg) translate(65%, -19%)",
                        "rotate(2deg) translate(95%, -26%)",
                        "rotate(6deg) translate(125%, -23%)",
                    ],
                    init() {
                        this.plates = [...document.querySelectorAll(".hpp_plate")];
                        // 给所有扑克牌编上序号，方便后面查找移动
                        this.plates.forEach((plate, index) => {
                            plate.index = index;
                        });
                        // 预加载所有图片
                        for (let i = 0; i < this.img_length; i++) {
                            let img = new Image();
                            img.src = `https://cdn.jiejoe.com/photos/photo_${i + 1}.webp`;
                            this.imgs.push(img);
                        }
                        this.img_index = this.plates.length;
                    },
                    // 移动所有图片
                    move_imgs() {
                        this.plates.forEach((plate) => {
                            let index = plate.index;
                            if (index + 1 >= this.plates.length) {
                                // 当扑克牌超出图片总长度，则序数归零回到牌头
                                index = 0;
                                plate.style.transition = ""; //设置无过渡：马上回到牌头
                                plate.querySelector("img").src = ""; //防止网络不好：导致图片显示延迟
                                plate.querySelector("img").src = this.imgs[this.img_index].src; //更改图片
                                // 更新图片序数
                                this.img_index = (this.img_index + 1) % this.imgs.length;
                            } else {
                                // 扑克牌未超出图片总长度，则正常过渡移动
                                index += 1;
                                plate.style.transition = "transform 0.3s ease";
                            }
                            //更新扑克牌序数和层叠顺序
                            plate.index = index;
                            plate.style.zIndex = index;
                            plate.style.transform = this.transform_datas[index]; //移动图片
                        });
                    },
                    remove() {
                        this.plates = null;
                        this.img = null;
                        this.transform_datas = null;
                    },
                },
                // 联系板块
                contact: {
                    container: null,
                    observer: null, //观察者
                    svg_telphone: require("../assets/images/home/telephone.svg"), // 电话纹理
                    scale_nums: 0, // 电话的大小缩放比例
                    // 物理引擎渲染参数
                    phone: null,
                    width: 0,
                    height: 0,
                    engine: null,
                    render: null,
                    boxes: null, // 画布中的所有物体
                    if_run_engine: false, //是否已运行物理引擎
                    if_speed_checkable: false, // 是否可以检查phone速度
                    speed_checker: null, // 用于检查phone速度的定时器
                    // 用于接受lenis全局滑动控制器
                    scroll_controler: null,
                    init() {
                        this.container = document.querySelector(".home_contact");
                        this.phone = document.querySelector(".home_contact_phone");
                        this.creat_engine();
                        this.resize();
                        this.updata_box();
                        this.create_mouse_events();
                        this.observe_container();
                    },
                    // 创建引擎和渲染器
                    creat_engine() {
                        this.engine = Engine.create({
                            constraintIterations: 1,
                        });
                        this.render = Render.create({
                            element: this.phone,
                            engine: this.engine,
                            options: {
                                width: this.phone.offsetWidth,
                                height: this.phone.offsetHeight,
                                background: `#00000000`,
                                wireframes: false,
                            },
                        });
                    },
                    resize() {
                        this.width = this.phone.offsetWidth;
                        this.height = this.phone.offsetHeight;
                        // 更新渲染器的宽高
                        this.render.canvas.width = this.width;
                        this.render.canvas.height = this.height;
                        // 缩放比例数值:以宽度为基准/300的宽度为1
                        this.scale_nums = this.width / 300;
                        // 检测如果是safari浏览器：则再缩小3倍缩放系数，否则在safari上会出现物体过大的BUG
                        if (this.if_safari) this.scale_nums = this.scale_nums / 3;
                        if (this.boxes)
                            // 重新将物体加入到世界
                            this.updata_box();
                    },
                    // 将物体加入到世界
                    updata_box() {
                        // 清除世界里的phone:注意！这里不用Composite的clear方法，需要保留鼠标约束
                        if (this.boxes) Composite.remove(this.engine.world, this.boxes, false);
                        // phone的宽高由canvas的宽度决定
                        let phone = Bodies.rectangle(
                            this.width / 2,
                            this.height / 2.5,
                            this.width / 16,
                            this.width / 8,
                            {
                                render: {
                                    sprite: {
                                        texture: this.svg_telphone, // 加入电话纹理
                                        xScale: 0.45 * this.scale_nums,
                                        yScale: 0.45 * this.scale_nums,
                                    },
                                },
                            }
                        );
                        // 创建一个静态刚体作为弹簧的固定点
                        let anchor = { x: this.width / 2, y: 0 };
                        // 创建一个弹簧约束，连接小球和固定点
                        let spring = Constraint.create({
                            pointA: anchor,
                            bodyB: phone,
                            // 连接点处于电话顶部
                            pointB: { x: 0, y: -this.width / 10 },
                            stiffness: 0.1,
                            render: {
                                lineWidth: 2 * this.scale_nums,
                                strokeStyle: "#f7f7f7",
                            },
                        });
                        // 将所有物体加入到world
                        this.boxes = [phone, spring];
                        Composite.add(this.engine.world, this.boxes);
                        // 给电话一个初始位置，让进入视口时电话可以由重力运动
                        Body.setPosition(phone, {
                            x: this.width / 3,
                            y: this.height / 2.5,
                        });
                    },
                    // 创建鼠标控制，让用户可以用鼠标控制画布中的电话
                    create_mouse_events() {
                        let mouse = Mouse.create(this.render.canvas);
                        // 创建matter鼠标约束
                        let mouseConstraint = MouseConstraint.create(this.engine, {
                            mouse,
                            constraint: {
                                stiffness: 0.2,
                                render: {
                                    visible: false,
                                },
                            },
                        });
                        // 将鼠标约束添加到世界中
                        Composite.add(this.engine.world, mouseConstraint);
                        // 让渲染器知道要跟踪哪个元素，以便正确处理鼠标的位置
                        this.render.mouse = mouse;
                        // 取消wheel事件对物理引擎的影响,否则当用户用手指拖拽canvas的时候，会判定为滑动事件，没法处理移动端触碰
                        // this.render.canvas.addEventListener("wheel", () => {}, {
                        //     passive: true,
                        // });
                        // mousedown/touchstart:按下电话，开启速度检查
                        Events.on(mouseConstraint, "mousedown", () => {
                            this.if_speed_checkable = true;
                        });
                        // mouseup/touchend:弹起电话，检查电话速度
                        Events.on(mouseConstraint, "mouseup", () => {
                            this.check_phone_speed();
                            this.if_speed_checkable = false; //关闭速度检查
                        });
                        // 当鼠标离开画布，模拟触发mouseConstraint的mouseup事件，或者电话可以被一直拖拽到画布外
                        this.render.canvas.onmouseleave = () => {
                            mouseConstraint.mouse.mouseup(mouseConstraint.mouse);
                        };
                        // 当触碰离开画布范围，模拟触发mouseConstraint的mouseup事件，或者电话可以被一直拖拽到画布外
                        this.render.canvas.ontouchmove = (e) => {
                            // 计算触摸点相对于画布的位置
                            const render_rect = this.render.canvas.getBoundingClientRect();
                            let x = e.touches[0].clientX - render_rect.left;
                            let y = e.touches[0].clientY - render_rect.top;
                            // 检查触摸点是否在画布内
                            if (x < 0 || x > render_rect.width || y < 0 || y > render_rect.height)
                                // 如果离开画布则模拟触发mouseConstraint的mouseup事件
                                mouseConstraint.mouse.mouseup(mouseConstraint.mouse);
                        };
                    },
                    // 检查电话速度，以确定是否回到页面顶部
                    check_phone_speed() {
                        if (!this.if_speed_checkable) return;
                        if (this.speed_checker) clearInterval(this.speed_checker); // 避免重复触发计时器
                        // 每30毫秒检查一次电话的速度
                        this.speed_checker = setInterval(() => {
                            let phone = this.boxes[0];
                            // 如果电话的速度超过或小于一定值，则停止检查
                            if (phone.speed > 15 * this.scale_nums || phone.speed < 0.3)
                                clearInterval(this.speed_checker);
                            // 如果电话的速度超过一定值，判断电话为拉伸绷紧状态，让页面弹回到顶端
                            if (phone.speed > 15 * this.scale_nums)
                                // 延迟一定时间，等电话向上弹起一段距离后，再回弹页面
                                setTimeout(() => {
                                    this.scroll_controler.scrollTo(0, { lock: true, duration: 2 });
                                }, 100);
                        }, 30);
                    },
                    // 观察容器，当该板块进入视口，添加监听，反正则取消
                    observe_container() {
                        this.observer = new IntersectionObserver((eles) => {
                            if (eles[0].isIntersecting) this.create_listener();
                            else this.remove_listener();
                        });
                        this.observer.observe(this.container);
                    },
                    // 创建监听
                    create_listener() {
                        // 启动runner，开始更新engine
                        Render.run(this.render);
                        this.if_run_engine = true;
                        this.update_engine();
                    },
                    // 取消监听
                    remove_listener() {
                        // 停止runner和engine
                        Render.stop(this.render);
                        this.if_run_engine = false;
                        clearInterval(this.speed_checker);
                    },
                    // 更新引擎,此处引擎的更新为手动,如果直接run引擎的话,没法关闭
                    update_engine() {
                        if (!this.if_run_engine) return;
                        // 此处引擎的更新为手动,如果直接run引擎的话,则没法关闭
                        Engine.update(this.engine);
                        requestAnimationFrame(this.update_engine.bind(this));
                    },
                    // 清除引擎,释放资源保证性能
                    clear_engine() {
                        this.if_speed_checkable = false;
                        clearInterval(this.speed_checker);
                        this.speed_checker = null;
                        this.if_run_engine = false;
                        Engine.clear(this.engine);
                        Render.stop(this.render);
                        this.render.canvas.remove();
                        Composite.clear(this.engine.world, false);
                        this.phone = null;
                        this.engine = null;
                        this.render = null;
                        this.boxes = null;
                    },
                    remove() {
                        this.clear_engine();
                        this.observer.unobserve(this.container);
                        this.observer = null;
                        this.container = null;
                        this.svg_telphone = null;
                    },
                },
            },
        };
    },
    methods: {
        resize() {
            Object.values(this.pages).forEach((page) => {
                if (page.resize) page.resize();
            });
        },
    },
    mounted() {
        if (
            navigator.userAgent.includes("Safari") &&
            !navigator.userAgent.includes("Chrome") &&
            !navigator.userAgent.includes("Chromium")
        )
            // 判断当前环境是否为safari
            this.pages.contact.if_safari = this.pages.idea.if_safari = true;
        this.pages.contact.scroll_controler = this.$parent.scroll_controler;
        this.$parent.init_animation = this.pages.wel.show;
        Object.values(this.pages).forEach((page) => {
            if (page.init) page.init();
        });
        window.addEventListener("resize", this.resize);
    },
    beforeDestroy() {
        // 调用所有page的remove函数，释放资源
        Object.values(this.pages).forEach((page) => {
            if (page.remove) page.remove();
        });
        window.removeEventListener("resize", this.resize);
    },
};
</script>

<style>
#home {
    position: relative;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.gray {
    filter: grayscale(1);
}

.home_welcome {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-bottom: 15rem;
}

.home_welcome_background {
    position: absolute;
    width: 100%;
    height: 100%;
}

.hwb_blurball1 {
    position: absolute;
    top: -30rem;
    left: -30rem;
    width: 80rem;
    height: 80rem;
}

.hwb_blurball2 {
    position: absolute;
    bottom: -25rem;
    right: -25rem;
    width: 60rem;
    height: 60rem;
}
.home_welcome_lines {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}
.home_welcome_lines div {
    position: absolute;
    width: 120vw;
    height: 0.1rem;
    background: linear-gradient(90deg, #00000000, #17f700);
    background-size: 200% 200%;
    background-position: 200% 0;
    animation: move_line 10s linear infinite;
}

.home_welcome_lines div:nth-child(1) {
    top: 10%;
    transform: rotate(-20deg);
}
.home_welcome_lines div:nth-child(2) {
    left: -50%;
    transform: rotate(-80deg);
    animation-delay: -2s;
}
.home_welcome_lines div:nth-child(3) {
    left: 5%;
    top: 15%;
    transform: rotate(30deg);
    animation-delay: -5s;
}
.home_welcome_lines div:nth-child(4) {
    bottom: 20%;
    transform: rotate(-5deg);
    animation-delay: 5s;
}
.home_welcome_lines div:nth-child(5) {
    bottom: -20%;
    transform: rotate(-30deg);
    animation-delay: 2s;
}
@keyframes move_line {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: 0% 0;
    }
}
.home_welcome_logo {
    position: absolute;
    width: 20rem;
    height: 20rem;
    transform: translate(108%, -15%);
}
.home_welcome_main {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 65rem;
    height: 30rem;
    margin-bottom: 6rem;
    --scale_nums: 1;
}

.hwm_title {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.hwm_title div {
    opacity: 0;
    transform: translateX(-10rem);
}
.hwm_title p {
    position: relative;
    font-family: eng;
    font-size: calc(var(--scale_nums) * 9rem);
    line-height: calc(var(--scale_nums) * 9rem);
    letter-spacing: calc(var(--scale_nums) * 0.5rem);
    text-transform: uppercase;
    white-space: nowrap;
}

.hwm_title div:nth-child(1) p {
    color: #00000000;
    -webkit-text-stroke: calc(var(--scale_nums) * 0.2rem) var(--theme_white);
    transform: translateX(15%);
}
.hwm_title div:nth-child(2) p {
    color: #00000000;
    -webkit-text-stroke: calc(var(--scale_nums) * 0.2rem) var(--theme_green);
    transform: translateX(-15%);
}
.hwm_title div:nth-child(3) p {
    color: #00000000;
    -webkit-text-stroke: calc(var(--scale_nums) * 0.2rem) var(--theme_white);
    transform: translateX(3%);
}
.hwm_title div:nth-child(3) p span {
    color: #00000000;
    -webkit-text-stroke: calc(var(--scale_nums) * 0.2rem) var(--theme_green);
    font-size: calc(var(--scale_nums) * 9rem);
}
.home_welcome_nobody {
    position: absolute;
    left: 15%;
    top: 14%;
    width: 5.5rem;
    height: 5.5rem;
    transform: rotate(0) scale(0);
}
.hwv_eye {
    fill: var(--theme_green);
}

.hwv_mouse {
    fill: none;
    stroke: var(--theme_green);
    stroke-width: 7;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.hwv_circle {
    transform-origin: center;
    animation: hwv_circle 5s linear infinite;
}

@keyframes hwv_circle {
    to {
        transform: rotate(360deg);
    }
}
.home_welcome_scrolltip {
    position: absolute;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    opacity: 0;
    transform: translateY(10%);
}
.hws_brand {
    width: 2.2rem;
    height: 4.4rem;
    margin-bottom: 0.3rem;
    overflow: visible;
    animation: hws_brand 5s ease infinite 0.1s;
}
@keyframes hws_brand {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    60% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.hws_brand_left,
.hws_brand_right {
    transform-origin: 106.214px -38.673px;
    fill: var(--theme_white);
}
.hws_brand_left {
    animation: hws_brand_left 5s ease infinite;
}
.hws_brand_right {
    animation: hws_brand_right 5s ease infinite;
}

@keyframes hws_brand_left {
    0% {
        transform: rotate(0deg);
    }
    5% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    55% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes hws_brand_right {
    0% {
        transform: rotate(0deg);
    }
    5% {
        transform: rotate(2deg);
    }
    50% {
        transform: rotate(2deg);
    }
    55% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.hws_brand_arrow {
    transform-origin: 275.1px 406.4159px;
    animation: hws_brand 5s ease infinite 0.1s;
}

.hws_brand_arrow path {
    fill: none;
    stroke: var(--theme_green);
    stroke-width: 22;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.home_welcome_scrolltip p {
    --scale_nums: 2;
    font-family: zh;
    font-size: calc(var(--scale_nums) * 0.7rem);
    color: var(--theme_green);
    line-height: calc(var(--scale_nums) * 1.2rem);
    letter-spacing: calc(var(--scale_nums) * 0.05rem);
    text-align: center;
    text-transform: uppercase;
    transform-origin: top;
    transform: scale(calc(1 / var(--scale_nums)));
}
.home_welcome_scrolltip span {
    font-size: calc(var(--scale_nums) * 0.7rem);
    color: var(--theme_white);
    line-height: calc(var(--scale_nums) * 1.1rem);
}

@media screen and (max-aspect-ratio: 1.5/1) {
    .home_welcome_main {
        --scale_nums: 1.3;
    }
    .home_welcome_logo {
        width: 26rem;
        height: 26rem;
        transform: translate(108%, -12%);
    }
    .home_welcome_nobody {
        left: 5%;
        top: 4%;
        width: 7.15rem;
        height: 7.15rem;
    }
}
@media screen and (max-aspect-ratio: 0.8/1) {
    .home_welcome_main {
        --scale_nums: 1;
    }
    .home_welcome_logo {
        width: 20rem;
        height: 20rem;
        transform: translate(108%, -15%);
    }
    .home_welcome_nobody {
        left: 15%;
        top: 14%;
        width: 5.5rem;
        height: 5.5rem;
    }
}
@media screen and (max-aspect-ratio: 0.65/1) {
    .home_welcome_logo {
        width: 40rem;
        height: 40rem;
        transform: translate(0%, -35%);
    }
    .home_welcome_main {
        margin-bottom: 0rem;
        margin-top: 30rem;
        margin-left: 4rem;
        --scale_nums: 0.75;
    }
    .home_welcome_nobody {
        left: 23%;
        top: 22%;
        width: 4.8rem;
        height: 4.8rem;
    }
    .home_welcome_scrolltip {
        bottom: 10%;
    }
}

.home_about {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: calc(var(--scale_nums) * 6.5rem);
    --scale_nums: 0.8;
}
.home_about_content {
    position: relative;
    flex-direction: column;
    align-items: start;
}
.hac_title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: var(--theme_green); */
    -webkit-text-stroke: calc(var(--scale_nums) * 0.1rem) var(--theme_green);
    margin-bottom: calc(var(--scale_nums) * 0.8rem);
    z-index: 1;
}
.hac_title,
.hac_title::after,
.hac_title::before {
    font-family: eng;
    font-size: calc(var(--scale_nums) * 10rem);
    color: #00000000;
    -webkit-text-stroke: calc(var(--scale_nums) * 0.1rem) var(--theme_green);
    letter-spacing: calc(var(--scale_nums) * 0.5rem);
    white-space: nowrap;
}
.hac_title {
    color: var(--theme_green);
}
.hac_title::after {
    content: attr(text);
    position: absolute;
    transition: transform 0.6s ease-out;
    clip-path: polygon(0% 75%, 100% 75%, 100% 100%, 0% 100%);
    z-index: -1;
}
.hac_title::before {
    content: attr(text);
    position: absolute;
    transition: transform 1s ease-out;
    clip-path: polygon(0% 80%, 100% 80%, 100% 100%, 0% 100%);
    z-index: -2;
}
.hac_introduce {
    position: relative;
    font-family: zh;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_white);
    letter-spacing: calc(var(--scale_nums) * 0.15rem);
    line-height: calc(var(--scale_nums) * 1.6rem);
}
.hac_line {
    position: absolute;
    right: -15%;
    bottom: -12%;
    width: calc(var(--scale_nums) * 20rem);
    height: calc(var(--scale_nums) * 9em);
    transform: rotate(5deg);
    z-index: 2;
}
.hac_line path {
    fill: none;
    stroke: var(--theme_white);
    stroke-width: 28;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 4700;
    stroke-dashoffset: 4700;
    transition: stroke-dashoffset 1.2s ease-out;
}
.home_about_content_show .hac_title::after {
    transform: translateY(10%) scale(0.95);
}
.home_about_content_show .hac_title::before {
    transform: translateY(16%) scale(0.9);
}
.home_about_content_show .hac_line path {
    stroke-dashoffset: 0;
}
@media screen and (max-aspect-ratio: 0.5/1) {
    .home_about {
        --scale_nums: 0.6;
    }
}
.home_portrait {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50rem;
    overflow: hidden;
    margin-bottom: 5rem;
}
.home_portrait_main {
    position: relative;
    justify-content: center;
    width: 70rem;
    height: 35rem;
    border-radius: 2rem;
    overflow: hidden;
    z-index: 0;
}
.hpm_imgbox {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.hpm_imgbox img {
    width: 100%;
}
.home_portrait_textline {
    box-sizing: border-box;
    height: 35rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin: 0 2rem;
}
.home_portrait_textline div {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 0.3rem;
    background-color: var(--theme_white);
    transform: rotate(45deg);
    animation: rotate_cross1 3s ease infinite;
}
.home_portrait_textline div:nth-child(3) {
    transform: rotate(0deg) scale(0.7);
    animation: rotate_cross2 3s ease infinite;
}

.home_portrait_textline div::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--theme_white);
    transform: rotate(90deg);
}
.home_portrait_textline p {
    font-family: zh;
    font-size: 1rem;
    color: var(--theme_green);
    letter-spacing: 0.2rem;
    writing-mode: vertical-rl;
    text-transform: uppercase;
}
@keyframes rotate_cross1 {
    0% {
        transform: rotate(40deg);
    }
    40% {
        transform: rotate(45deg);
    }
    50% {
        transform: rotate(90deg);
    }
    90% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(135deg);
    }
}
@keyframes rotate_cross2 {
    0% {
        transform: rotate(0deg) scale(0.7);
    }
    40% {
        transform: rotate(0deg) scale(0.7);
    }
    50% {
        transform: rotate(45deg) scale(0.7);
    }
    90% {
        transform: rotate(45deg) scale(0.7);
    }
    100% {
        transform: rotate(90deg) scale(0.7);
    }
}
.home_portrait_line {
    position: absolute;
    top: 7.5rem;
}
.home_portrait_line div {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 110vw;
    height: 0.1rem;
    background: linear-gradient(90deg, #00000000, #17f700, #00000000);
    transform: scaleX(0);
    transition: transform 0.3s ease;
    z-index: 1;
}
.home_portrait_line div::after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 0.5rem;
    background-color: var(--theme_green);
    border-radius: 50%;
    filter: blur(1rem);
    opacity: 0.8;
    z-index: -1;
}
.home_portrait_line div::before {
    content: "";
    position: absolute;
    width: 40rem;
    height: 1rem;
    background-color: var(--theme_green);
    border-radius: 100%;
    filter: blur(0.3rem);
    opacity: 0.6;
    z-index: -1;
}
.home_portrait_line_show div {
    transform: scaleX(1);
}
@media screen and (max-aspect-ratio: 0.5/1) {
    .hpm_imgbox img {
        width: auto;
        height: 100%;
    }
}
.home_skills {
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding-top: 5rem;
}
.home_skills_title {
    --scale_nums: 1;
    position: relative;
    align-self: start;
    font-family: eng;
    font-size: calc(var(--scale_nums) * 3rem);
    color: var(--theme_white);
    line-height: calc(var(--scale_nums) * 3.2rem);
    letter-spacing: calc(var(--scale_nums) * 0.2rem);
    text-transform: uppercase;
    margin-left: 10rem;
    margin-bottom: 10rem;
}
.home_skills_title span {
    font-size: calc(var(--scale_nums) * 3rem);
    color: var(--theme_green);
}
.home_skills_title::after {
    content: "?";
    position: absolute;
    right: calc(var(--scale_nums) * -4rem);
    top: calc(var(--scale_nums) * 1rem);
    font-family: eng;
    font-size: calc(var(--scale_nums) * 10rem);
    color: #00000000;
    -webkit-text-stroke: calc(var(--scale_nums) * 0.05rem) var(--theme_green);
    transform: rotate(-30deg) scale(0);
    transition: transform 0.5s ease;
}

.home_skills_title_show::after {
    transform: rotate(15deg);
}
.home_skills_skill {
    justify-content: center;
    align-items: center;
    width: 60rem;
    margin-bottom: 15rem;
}

.hss_text {
    position: relative;
    flex-direction: column;
    margin-right: 5rem;
}

.hss_text p:nth-child(1) {
    font-family: eng;
    font-size: 2rem;
    color: var(--theme_white);
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-bottom: 0.5rem;
    transform: translateX(-10%);
    opacity: 0;
}

.hss_text p:nth-child(2),
.hss_text p:nth-child(3) {
    font-family: eng;
    font-size: 4rem;
    color: var(--theme_green);
    line-height: 3.5rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    opacity: 0;
}
.hss_text p:nth-child(2) {
    transform: scaleY(2) translateY(25%);
}
.hss_text p:nth-child(3) {
    transform: scaleY(0.8) translateY(50%);
    color: #00000000;
    -webkit-text-stroke: 0.1rem var(--theme_green);
}
.hss_text p:nth-child(4) {
    font-family: zh;
    font-size: 1.3rem;
    color: var(--theme_white);
    letter-spacing: 0.3rem;
    margin: 1.5rem 0 4rem 0;
    opacity: 0;
}

.hss_text p:nth-child(5) {
    position: relative;
    font-family: eng;
    font-size: 4.5rem;
    color: #00000000;
    letter-spacing: 0.3rem;
    -webkit-text-stroke: 0.1rem var(--theme_white);
}
.hss_block {
    height: 23rem;
    width: 23rem;
    border: 0.1rem solid var(--theme_white);
    border-radius: 1rem;
}

.hss_block_canvas {
    position: relative;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    transform: translate(-5%, -5%);
}

.home_skills > div:nth-child(1) {
    transform: translateX(-20%);
}

.home_skills > div:nth-child(2) {
    transform: translateX(20%);
}

.home_skills > div:nth-child(3) {
    transform: translateX(-20%);
}

@media screen and (max-aspect-ratio: 0.75/1) {
    .home_skills_skill {
        flex-direction: column;
        margin-bottom: 10rem;
    }
    .home_skills_title {
        --scale_nums: 1.3;
        align-self: center;
        margin-left: 0rem;
        margin-bottom: 15rem;
    }
    .hss_text {
        width: 35rem;
        margin-bottom: 2rem;
        margin-right: 0rem;
    }

    .hss_text p:nth-child(5) {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 6rem;
    }

    .hss_block {
        height: 35rem;
        width: 35rem;
    }

    .home_skills > div:nth-child(1) {
        transform: translateX(0);
    }

    .home_skills > div:nth-child(2) {
        transform: translateX(0);
    }

    .home_skills > div:nth-child(3) {
        transform: translateX(0);
    }
}

.home_idea {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50rem;
    margin-bottom: -10rem;
    border-bottom-left-radius: 10rem;
    border-bottom-right-radius: 10rem;
    background-color: var(--theme_black);
    overflow: hidden;
    z-index: 1;
}

.home_idea_background {
    position: absolute;
    width: 100%;
    height: 100%;
}

.home_idea_container {
    --scale_nums: 1;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: calc(var(--scale_nums) * 60rem);
    margin-top: calc(var(--scale_nums) * -15rem);
}

.home_idea_container p {
    font-family: eng;
    color: var(--theme_white);
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 1;
}

.home_idea_container p:nth-child(1) {
    font-size: calc(var(--scale_nums) * 4rem);
    letter-spacing: calc(var(--scale_nums) * 4rem / 30);
}

.home_idea_container p:nth-child(2) {
    font-size: calc(var(--scale_nums) * 7.5rem);
    line-height: calc(var(--scale_nums) * 8rem);
    letter-spacing: calc(var(--scale_nums) * 7.5rem / 40);
}

.home_idea_container p:nth-child(2) span {
    font-size: calc(var(--scale_nums) * 7.5rem);
    color: var(--theme_green);
}

.home_idea_container p:nth-child(3) {
    font-size: calc(var(--scale_nums) * 6rem);
    letter-spacing: calc(var(--scale_nums) * 6rem / 30);
}
.home_idea_container p:nth-child(3) span {
    font-size: calc(var(--scale_nums) * 6rem);
    color: #0000;
    -webkit-text-stroke: calc(var(--scale_nums) * 0.15rem) var(--theme_green);
}

.hic_brain {
    position: absolute;
    bottom: 10%;
    right: -7%;
    width: calc(var(--scale_nums) * 10rem);
    z-index: 0;
}

.hic_horse {
    position: absolute;
    top: -40%;
    left: 0%;
    width: calc(var(--scale_nums) * 10rem);
    transform: rotate(-15deg);
    z-index: 0;
}

@media screen and (max-aspect-ratio: 0.7/1) {
    .home_idea {
        border-bottom-left-radius: 7rem;
        border-bottom-right-radius: 7rem;
    }
    .home_idea_container {
        --scale_nums: 0.8;
    }
}
@media screen and (max-aspect-ratio: 0.6/1) {
    .home_idea_container {
        --scale_nums: 0.7;
    }
}
@media screen and (max-aspect-ratio: 0.5/1) {
    .hic_brain {
        right: 0%;
        bottom: 8%;
    }

    .hic_horse {
        top: -75%;
        left: 3%;
    }
}

.home_vision {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60rem;
    overflow: hidden;
    background-color: var(--theme_green);
    z-index: -1;
}

.home_vision_scrolllines {
    position: relative;
    height: 12rem;
    flex-shrink: 0;
    transform: translateY(5rem);
}
.home_vision .home_vision_scrolllines:nth-child(odd) {
    align-self: start;
    justify-content: start;
}
.home_vision .home_vision_scrolllines:nth-child(even) {
    align-self: end;
    justify-content: end;
}
.hvs_line {
    position: relative;
    height: 100%;
}
.home_vision .home_vision_scrolllines:nth-child(odd) .hvs_line {
    justify-content: start;
    animation: vision_scrollline_mov 8s linear infinite;
}
.home_vision .home_vision_scrolllines:nth-child(even) .hvs_line {
    justify-content: end;
    animation: vision_scrollline_mov_reverse 8s linear infinite;
}
@keyframes vision_scrollline_mov {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}
@keyframes vision_scrollline_mov_reverse {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}
.hvs_line div {
    font-family: eng;
    font-size: 12rem;
    color: var(--theme_black);
    line-height: 12rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin-right: 4rem;
    flex-shrink: 0;
}

.hvs_line p {
    font-size: 12rem;
    color: #00000000;
    line-height: 12rem;
    letter-spacing: 0.2rem;
    -webkit-text-stroke: 0.3rem var(--theme_black);
    margin-left: 2rem;
}
.home_vision_block {
    position: absolute;
    width: 38rem;
    height: 38rem;
    background-color: var(--theme_black);
    border-radius: 2rem;
    margin-top: 10rem;
}
.home_vision_block P {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-family: zh;
    font-size: 1.5rem;
    color: var(--theme_green);
}
.home_vision_block P::after {
    content: "";
    position: absolute;
    width: 3.2rem;
    height: 0.1rem;
}
.home_vision_block P::before {
    content: "";
    position: absolute;
    width: 0.1rem;
    height: 3.2rem;
}
.home_vision_block P:nth-child(1) {
    top: 0.8rem;
    left: 0.8rem;
}

.home_vision_block P:nth-child(2) {
    top: 0.8rem;
    right: 0.8rem;
}

.home_vision_block P:nth-child(3) {
    bottom: 0.8rem;
    left: 0.8rem;
}

.home_vision_block P:nth-child(4) {
    bottom: 0.8rem;
    right: 0.8rem;
}
.home_vision_block P:nth-child(1)::after,
.home_vision_block P:nth-child(3)::after {
    left: 2rem;
    background: linear-gradient(90deg, #f7f7f7, #00000000);
}
.home_vision_block P:nth-child(2)::after,
.home_vision_block P:nth-child(4)::after {
    right: 2rem;
    background: linear-gradient(90deg, #00000000, #f7f7f7);
}
.home_vision_block P:nth-child(1)::before,
.home_vision_block P:nth-child(2)::before {
    top: 2.4rem;
    background: linear-gradient(0deg, #00000000, #f7f7f7);
}
.home_vision_block P:nth-child(3)::before,
.home_vision_block P:nth-child(4)::before {
    bottom: 2.4rem;
    background: linear-gradient(180deg, #00000000, #f7f7f7);
}

#home_vision_spidereye {
    position: absolute;
    width: 40rem;
    margin-top: 10rem;
}

@media screen and (max-aspect-ratio: 0.8/1) {
    .home_vision_text {
        width: 18rem;
    }
}

.home_works {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: calc(var(--scale_nums) * 15rem);
    padding-bottom: calc(var(--scale_nums) * 5rem);
    margin-bottom: calc(var(--scale_nums) * 8rem);
    --scale_nums: 1;
}
.home_works_title {
    position: relative;
    margin-bottom: calc(var(--scale_nums) * 2rem);
    transform: scaleX(0.9);
}
.home_works_title p {
    font-family: eng;
    font-size: calc(var(--scale_nums) * 8rem);
    letter-spacing: calc(var(--scale_nums) * 0.3rem);
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(10%);
    color: #00000000;
    -webkit-text-stroke: 0.1rem var(--theme_green);
}

.home_works_zh {
    font-family: zh;
    font-size: calc(var(--scale_nums) * 1.2rem);
    color: var(--theme_white);
    letter-spacing: calc(var(--scale_nums) * 0.15rem);
    margin-bottom: calc(var(--scale_nums) * 0.2rem);
}

.home_works_eng {
    font-family: zh;
    font-size: calc(var(--scale_nums) * 0.6rem * 2);
    color: var(--theme_white);
    letter-spacing: calc(var(--scale_nums) * 0.05rem * 2);
    text-transform: uppercase;
    text-align: center;
    transform-origin: top;
    transform: scale(0.5);
}

.home_works_line {
    position: absolute;
    top: 88%;
    width: calc(var(--scale_nums) * 0.15rem);
    height: calc(var(--scale_nums) * 14rem);
    background: linear-gradient(#17f700, #00000000);
    border-radius: calc(var(--scale_nums) * 0.5rem);
}

@media screen and (max-aspect-ratio: 0.5/1) {
    .home_works {
        --scale_nums: 0.8;
    }
}
.home_videotitle {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20rem;
    margin-bottom: 5rem;
    overflow: hidden;
    cursor: pointer;
}

.home_videotitle_pmd {
    height: 30rem;
}

.home_videotitle_text,
.home_videotitle_textline {
    position: absolute;
    font-family: eng;
    font-size: 12rem;
    transform: scaleY(80%);
    text-transform: uppercase;
}
.home_videotitle_text {
    color: var(--theme_green);
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}

.home_videotitle_textline {
    -webkit-text-stroke: 0.15rem var(--theme_green);
    color: #00000000;
}

.home_videotitle_frames {
    position: absolute;
    width: 100%;
    height: 15rem;
}

.hvf_frame {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    flex-shrink: 0;
    /* 开启硬件加速 */
    transform: translate3d(0, 0, 0);
}

.hvf_frame_img {
    align-items: center;
    height: 100%;
    margin-right: 1rem;
    transform-origin: right;
    overflow: hidden;
    flex-shrink: 0;
    border: 0.2rem solid var(--theme_white);
}

.hvf_frame_img img {
    height: 100%;
}

.home_videotitle_mousetip {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 6rem;
    height: 6rem;
    margin-left: -3rem;
    margin-top: -3rem;
    background-color: var(--theme_green);
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
}

.home_videotitle_mousetip p {
    position: absolute;
    font-family: eng;
    font-size: 2rem;
    color: var(--theme_black);
    letter-spacing: 0.1rem;
}

.home_videotitle_mousetip svg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.home_videotitle_mousetip_circle {
    fill: none;
    stroke-width: 1.5;
    stroke: var(--theme_white);
    stroke-linecap: round;
    stroke-dasharray: 160;
    stroke-dashoffset: 160;
}

.home_videos {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.home_videos_video {
    --scale_nums: 1;
    box-sizing: border-box;
    position: relative;
    justify-content: start;
    align-items: center;
    width: 90%;
    height: calc(var(--scale_nums) * 15rem);
    padding-left: 5%;
    border-top: 0.1rem solid var(--theme_white);
    cursor: pointer;
    z-index: 0;
}

.home_videos_video::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme_white);
    clip-path: polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%);
    transition: clip-path 0.4s ease;
    z-index: 0;
}
.hvv_nums {
    font-family: eng;
    font-size: calc(var(--scale_nums) * 6rem);
    color: #0000;
    -webkit-text-stroke: 0.1rem var(--theme_white);
    letter-spacing: calc(var(--scale_nums) * 0.2rem);
    margin-right: calc(var(--scale_nums) * 2rem);
    transition: -webkit-text-stroke 0.4s ease, color 0.4s ease;
    z-index: 1;
}
.hvv_information {
    flex-direction: column;
}
.hvv_information p:nth-child(1) {
    font-family: eng;
    font-size: calc(var(--scale_nums) * 3.5rem);
    color: var(--theme_white);
    line-height: calc(var(--scale_nums) * 4rem);
    letter-spacing: calc(var(--scale_nums) * 0.1rem);
    white-space: nowrap;
    margin-bottom: calc(var(--scale_nums) * 1rem);
    transition: color 0.4s ease;
    z-index: 1;
}

.hvv_information p:nth-child(2) {
    font-family: zh;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_white);
    line-height: calc(var(--scale_nums) * 1.3rem);
    letter-spacing: calc(var(--scale_nums) * 0.2rem);
    white-space: nowrap;
    transition: color 0.4s ease;
    z-index: 1;
}
.hvv_time {
    position: absolute;
    right: 5%;
    font-family: zh;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_white);
    letter-spacing: calc(var(--scale_nums) * 0.15rem);
    transition: color 0.4s ease;
    z-index: 1;
}

@media (hover: hover) {
    .home_videos_video:hover::after {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
    .home_videos_video:hover p {
        color: var(--theme_black);
    }
    .home_videos_video:hover .hvv_nums {
        color: var(--theme_green);
        -webkit-text-stroke: 0rem var(--theme_white);
    }
}
.home_videos_card {
    position: fixed;
    left: 0;
    top: 0;
    width: 32rem;
    height: 20rem;
    background-color: var(--theme_white);
    border-radius: 1rem;
    margin-left: -3rem;
    margin-top: -3rem;
    overflow: hidden;
    transform: scale(0) rotate(30deg);
    pointer-events: none;
}
.home_videos_card img {
    position: relative;
    height: 100%;
}
@media screen and (max-aspect-ratio: 0.9/1) {
    .home_videos_video {
        padding-left: 0;
    }
    .hvv_time {
        right: 0%;
    }
}
@media screen and (max-aspect-ratio: 0.7/1) {
    .home_videos_video {
        --scale_nums: 0.8;
    }
    .hvv_time {
        bottom: 10%;
    }
}
@media screen and (max-aspect-ratio: 0.45/1) {
    .home_videos_video {
        --scale_nums: 0.6;
    }
}

.home_photos {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60rem;
    margin-bottom: 10rem;
}

.home_photos_box {
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 45rem;
}

.hpb_title {
    font-family: eng;
    font-size: 13rem;
    color: #00000000;
    letter-spacing: 0.3rem;
    -webkit-text-stroke: 0.15rem var(--theme_white);
    transform: scaleY(70%);
}

.home_photos_plates {
    position: relative;
    width: 45rem;
    height: 25rem;
    margin-bottom: 1rem;
}

.hpp_plate,
.hpp_top {
    box-sizing: border-box;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 26rem;
    border: 0.15rem solid var(--theme_white);
    border-radius: 1.5rem;
    background-color: var(--theme_black);
    transform-origin: bottom left;
    overflow: hidden;
    cursor: pointer;
}

.hpp_plate img {
    position: relative;
    width: 100%;
    transition: transform 0.3s ease;
}

@media (hover: hover) {
    .hpp_plate:hover img {
        transform: scale(1.1);
    }
}
.hpp_plate1 {
    transform: rotate(-10deg);
}

.hpp_plate2 {
    transform: rotate(-6deg) translate(35%, -12%);
}

.hpp_plate3 {
    transform: rotate(-2deg) translate(65%, -19%);
}

.hpp_plate4 {
    transform: rotate(2deg) translate(95%, -26%);
}

.hpp_plate5 {
    transform: rotate(6deg) translate(125%, -23%);
}

.hpp_top {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--theme_white);
    border: none;
    cursor: pointer;
    z-index: 1000;
}

.hpp_top_arm {
    position: absolute;
    width: 60%;
    bottom: -7rem;
    left: 4rem;
    overflow: visible;
    transform: translateY(40%);
    transition: 0.5s ease;
}
.hpp_top_arm g {
    transform-origin: bottom;
    animation: arm_rotate 2.5s ease infinite;
}
.hpp_top_arm_hand {
    transform-origin: 86.5px 375.4724px;
    animation: hand_rotate 2.5s ease infinite;
}
@media (hover: hover) {
    .hpp_top:hover .hpp_top_arm {
        transform: translateY(0);
    }
}
@media (hover: none) {
    .hpp_top_arm {
        transform: translateY(0%);
    }
}

@keyframes arm_rotate {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(-3deg);
    }
    30% {
        transform: rotate(5deg);
    }
    40% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(5deg);
    }
    80% {
        transform: rotate(0deg);
    }
}
@keyframes hand_rotate {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(-6deg);
    }
    30% {
        transform: rotate(8deg);
    }
    40% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(8deg);
    }
    80% {
        transform: rotate(0deg);
    }
}

.home_photos_tip {
    font-family: eng;
    font-size: 1.5rem;
    color: var(--theme_white);
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
}

.home_photos_time {
    font-family: eng;
    font-size: 1.2rem;
    color: var(--theme_green);
    letter-spacing: 0.2rem;
}

.home_photos_time span {
    font-size: 1.2rem;
    color: var(--theme_white);
}

@media screen and (max-aspect-ratio: 0.6/1) {
    .home_photos_box {
        transform: scale(0.8);
    }
}

.home_contact {
    --scale: 1;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.home_contact_text {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10001;
    pointer-events: none;
}

.hct_title {
    position: relative;
    font-family: eng;
    font-size: calc(var(--scale) * 8rem);
    color: var(--theme_white);
    line-height: calc(var(--scale) * 6rem);
    letter-spacing: calc(var(--scale) * 0.2rem);
    text-transform: uppercase;
    white-space: nowrap;
}

.home_contact_text img:nth-of-type(1) {
    position: absolute;
    top: calc(var(--scale) * -3.5rem);
    left: calc(var(--scale) * 7rem);
    width: calc(var(--scale) * 4.5rem);
    transform: rotate(15deg);
}

.home_contact_text img:nth-of-type(2) {
    position: absolute;
    right: calc(var(--scale) * 2rem);
    bottom: calc(var(--scale) * -3rem);
    width: calc(var(--scale) * 5rem);
    transform: rotate(-5deg);
}

.home_contact_text img:nth-of-type(3) {
    position: absolute;
    top: calc(var(--scale) * -2rem);
    right: calc(var(--scale) * 8rem);
    width: calc(var(--scale) * 4rem);
    transform: rotate(-15deg);
}

.home_contact_text img:nth-of-type(4) {
    position: absolute;
    bottom: calc(var(--scale) * -3rem);
    left: 0;
    width: calc(var(--scale) * 4rem);
    transform: rotate(5deg);
}
.home_contact_phone {
    position: relative;
    width: calc(var(--scale) * 30rem);
    height: calc(var(--scale) * 20rem);
    margin-bottom: calc(var(--scale) * -15rem);
    cursor: pointer;
    z-index: 10001;
}

.home_contact_bottom {
    position: absolute;
    bottom: calc(var(--scale) * -9rem);
    width: 100%;
    height: calc(var(--scale) * 10rem);
    background-color: var(--theme_black);
    border-bottom-left-radius: calc(var(--scale) * 10rem);
    border-bottom-right-radius: calc(var(--scale) * 10rem);
    pointer-events: none;
    z-index: 1000;
}

@media screen and (max-aspect-ratio: 0.5/1) {
    .home_contact {
        --scale: 0.8;
    }
}
@media screen and (max-aspect-ratio: 0.8/1) {
    .home_contact_bottom {
        border-bottom-left-radius: calc(var(--scale) * 7rem);
        border-bottom-right-radius: calc(var(--scale) * 7rem);
    }
}

.home_bottom {
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--theme_white);
    padding-top: calc(var(--scale_nums) * 13rem);
    --scale_nums: 1;
}

.home_bottom_messages {
    margin-bottom: calc(var(--scale_nums) * 4rem);
}

.hbm_message {
    flex-direction: column;
    align-items: center;
    width: calc(var(--scale_nums) * 18rem);
}

.hbm_message img {
    width: calc(var(--scale_nums) * 5rem);
    height: calc(var(--scale_nums) * 5rem);
    margin-bottom: calc(var(--scale_nums) * 1rem);
}

.hbm_message p {
    text-align: center;
    font-family: zh;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_black);
    user-select: all;
}

.hbm_message p span {
    font-family: eng;
    font-size: calc(var(--scale_nums) * 1rem);
    user-select: none;
    margin-right: calc(var(--scale_nums) * 0.6rem);
}

.home_bottom_bottom {
    justify-content: space-between;
    width: 90%;
    border-bottom: calc(var(--scale_nums) * 0.15rem) solid var(--theme_black);
    padding-bottom: calc(var(--scale_nums) * 0.1rem);
    margin-bottom: calc(var(--scale_nums) * 1rem);
}

.hbb_menu {
    justify-content: flex-start;
}

.hbb_menu p {
    font-family: eng;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_black);
    text-transform: uppercase;
    margin-right: calc(var(--scale_nums) * 1.2rem);
    transition: color 0.3s ease;
    cursor: pointer;
}

.hbb_link {
    font-family: zh;
    font-size: calc(var(--scale_nums) * 0.8rem);
    color: var(--theme_black);
    letter-spacing: calc(var(--scale_nums) * 0.05rem);
    text-decoration: none;
    transition: color 0.3s ease;
}

@media (hover: hover) {
    .hbb_menu p:hover {
        color: var(--theme_green);
    }
    .hbb_link:hover {
        color: var(--theme_green);
    }
}

@media screen and (max-aspect-ratio: 0.5/1) {
    .home_bottom {
        --scale_nums: 0.8;
    }
}
@media screen and (max-aspect-ratio: 0.4/1) {
    .hbm_message {
        width: calc(var(--scale_nums) * 14rem);
    }
}
</style>
