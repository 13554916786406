<template>
    <div id="photos">
        <canvas class="photos_photobox" :class="{photos_photobox_activated:if_movable}"></canvas>
        <div
            class="photos_checkbox"
            :class="{photos_checkbox_show:checkbox.state,photos_checkbox_activated:if_movable}"
            :style="{'visibility':checkbox.if_visible?'visible':'hidden'}"
            @click="this.check_hidden"
        >
            <div class="photos_checkbox_line photos_checkbox_line_after photos_checkbox_mover"></div>
            <div class="photos_checkbox_line photos_checkbox_line_front photos_checkbox_mover"></div>
            <img class="photos_checkbox_img photos_checkbox_mover" alt="checkbox" />
            <div class="photos_checkbox_content" :style="{'--scale':scale_nums}">
                <p class="pcc_name">
                    <span
                        v-for="(letter ,index) in img_selected.name"
                        :key="index"
                        :style="{'--i':parseInt(Math.random()*img_selected.name.length)*0.02}"
                    >{{letter}}</span>
                </p>
                <p class="pcc_time">{{img_selected.time}}</p>
            </div>
        </div>
        <div class="photos_draglines" :class="{photos_draglines_hidden:!if_movable}">
            <div :class="`photos_draglines_${dirction}`" v-for="dirction in ['up','right','down','left']" :key="dirction">
                <div>
                    <div v-for="index in 15" :key="index">
                        <p>DRAG</p>
                        <svg viewBox="0 0 50 50">
                            <polyline points="24.4,2.9 46.5,25 24.4,47.1" />
                            <line x1="3.5" y1="25" x2="46.5" y2="25" />
                        </svg>
                    </div>
                </div>
                <div>
                    <div v-for="index in 15" :key="index">
                        <p>DRAG</p>
                        <svg viewBox="0 0 50 50">
                            <polyline points="24.4,2.9 46.5,25 24.4,47.1" />
                            <line x1="3.5" y1="25" x2="46.5" y2="25" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="photos_mousetip" :class="{photos_mousetip_shut:mousetip.if_shutable}" v-if="mousetip.if_render">
            <svg class="photos_mousetip_icon" viewBox="0 0 200 200">
                <line x1="53.1" y1="53.1" x2="146.9" y2="146.9" />
                <line x1="53.1" y1="146.9" x2="146.9" y2="53.1" />
                <polyline points="121.2,53.1 146.9,53.1 146.9,78.8 " />
                <polyline points="78.8,146.9 53.1,146.9 53.1,121.2 " />
            </svg>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
export default {
    name: "photo",
    data() {
        return {
            // 图片的json数据
            json_photos_data: require("../assets/json/photos_data.json"),
            container: null,
            canvas: null,
            context: null,
            img_total: 0, // 图片补足后的总数
            line_nums: 0, // 所有图片的行数
            row_nums: 0, // 所有图片的列数
            refer_width: 1260, //图片的实际参考宽度
            refer_height: 1800, //图片的实际参考高度
            refer_scale: 4.5, //实际宽高到绘制宽高的缩放比例：决定图片在画布上的大小
            img_width: 0, //图片用于在canvas绘制的宽度
            img_height: 0, //图片用于在canvas绘制的高度
            img_margin: 120,
            // 图片纵横排列后的总宽高
            total_width: 0,
            total_height: 0,
            // 标准宽度1920下，标准高度1920下，缩放比例为1
            standard_width: 1920,
            standard_height: 1080,
            scale_nums: 1, //固定屏幕画布大小，无论屏幕什么尺寸，画布都是同样的大小
            draw_scale: 1, //用于图片绘制时的缩放大小比例，确保无论什么尺寸下，绘制出的图片都不会模糊
            // 图片数据
            imgs_data: [],
            //圆角矩形裁剪图片
            img_mask: null,
            // 是否可移动所有图片
            if_movable: false,
            // 被鼠标选中的图片
            img_selected: {},
            // 处于视图可见范围内的所有图片
            imgs_inview: [],
            // 用于change_imgs中检查图片显示隐藏的计时器
            change_timer: null,
            // 用于记录鼠标或手指的xy坐标
            mouse: {
                x: 0,
                y: 0,
                // 主要用于记录移动端:一开始手指点击的xy位置
                pro_x: 0,
                pro_y: 0,
            },
            // 缓动
            ease: {
                x: 0,
                y: 0,
                id: null,
                damping: (t) => t * 0.95, // 缓动函数
            },
            checkbox: {
                img: null,
                mover: [],
                state: false, // 当前的checkbox状态，true为打开，flase为关闭
                if_visible: false, // checkbox是否可见:这里不和state用同一个变量是因为在隐藏阶段：chekcbox要在内容完成动画之后再隐藏
                if_animating: false, // checkbox是否处于显隐动画变换中
            },
            mousetip: {
                ele: null,
                if_render: true, //是否渲染生成
                if_shutable: false, // 是否可关闭：对应mousetip的打开和关闭状态样式
                last_angle: 0,
            },
        };
    },
    methods: {
        init() {
            this.img_width = Math.floor(this.refer_width / this.refer_scale);
            this.img_height = Math.floor(this.refer_height / this.refer_scale);
            // DOM对象
            this.container = document.getElementById("photos");
            this.canvas = document.querySelector(".photos_photobox");
            this.context = this.canvas.getContext("2d");
            this.checkbox.img = document.querySelector(".photos_checkbox_img");
            this.checkbox.mover = [...document.querySelectorAll(".photos_checkbox_mover")];
            this.mousetip.ele = document.querySelector(".photos_mousetip");
            this.img_mask = new Image();
            this.img_mask.src = require("../assets/images/mask.webp");
            // 滑动鼠标滚轮，向鼠标滑动方向纵向移动图片
            window.addEventListener("wheel", this.wheel);
            window.addEventListener("resize", this.resize);
            this.resize();
            this.complement_data();
            this.create_canvas_events();
            this.create_imgs_data();
            // 如果屏幕触点不止一个，则判断为移动端手势，不显示mousetip
            if (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
                return (this.mousetip.if_render = false);
            this.bind_mousetip_events();
        },
        // 补足图片数据
        complement_data() {
            // 将图片数量补足，以至可以形成一个近似方正的长方形
            let length = this.json_photos_data.length;
            while (true) {
                let row = Math.ceil(Math.sqrt(length));
                let line = Math.floor(Math.sqrt(length));
                if (row <= line + 1) row = line + 2;
                if (row * line >= length && row - line >= 2) {
                    this.row_nums = row;
                    this.line_nums = line;
                    this.img_total = row * line;
                    // 计算所有图片排列总宽高
                    this.total_width =
                        this.row_nums * (this.img_width + this.img_margin) - this.img_margin;
                    this.total_height =
                        this.line_nums * (this.img_height + this.img_margin) - this.img_margin;
                    break;
                }
                length++;
            }
        },
        resize() {
            // 重新计算缩放比例
            this.scale_nums =
                innerWidth / this.standard_width / 2 + innerHeight / this.standard_height / 2;
            // 确保任意尺寸下的图片清晰度
            this.draw_scale = this.scale_nums * devicePixelRatio;
            this.canvas.width = this.canvas.clientWidth * devicePixelRatio;
            this.canvas.height = this.canvas.clientHeight * devicePixelRatio;
            // 设置checkbox中的图片样式：保持其在画面中央不变
            // 注意：这里不设置checkbox的文字内容大小：因为scale_nums已经放到了checkbox的自定义css属性上了
            gsap.set(this.checkbox.mover, {
                width: `${this.img_width * this.scale_nums}px`,
                height: `${this.img_height * this.scale_nums}px`,
                x: `${(innerWidth - this.img_width * this.scale_nums) / 2}px`,
                y: `${
                    (innerHeight - this.img_height * this.scale_nums) / 2 - 40 * this.scale_nums
                }px`,
            });
            // 更新页面尺寸的时候，图片会被清空，所以调用一次move_imgs，绘制一下图片
            if (this.imgs_data.length != 0) this.move_imgs(0, 0); //必须在imgs_data完成创建之后才可以绘制
        },
        // 创建图片数据
        create_imgs_data() {
            for (let i = 0; i < this.img_total; i++) {
                let max_index = this.json_photos_data.length - 1;
                let middle_index = parseInt(max_index / 2);
                let img_index = i <= max_index ? i : (i % (middle_index + 1)) + middle_index; //补足后的实际图片序数
                let img = new Image(this.img_width, this.img_height);
                img.src = `https://cdn.jiejoe.com/photos/${this.json_photos_data[img_index].imgurl}`;
                // img.src = require(`../assets/images/photos/${this.json_photos_data[img_index].imgurl}`);
                // 获取行列序号
                let row_index = i % this.row_nums;
                let line_index = Math.floor(i / this.row_nums);
                // 通过行列序号计算xy坐标
                let x = row_index * (this.img_width + this.img_margin);
                let y = line_index * (this.img_height + this.img_margin);
                // 如果为偶数行，则向下偏移一段距离，制造行列交错的效果
                if (row_index % 2) y -= (this.img_height + this.img_margin) / 2;
                this.imgs_data.push({
                    x,
                    y,
                    name: this.json_photos_data[img_index].name,
                    time: this.json_photos_data[img_index].time,
                    index: i,
                    if_show: true, //用于在图片显示或隐藏的时候设置随机效果
                    if_loaded: false, //图片是否加载完毕：在图片还未加载完全的时候，避免页面空白，用圆角矩形填充对应位置
                });
                img.onload = () => {
                    this.imgs_data[i].img = img;
                    this.imgs_data[i].if_loaded = true;
                };
            }
        },
        // 绑定canvas的所有事件
        create_canvas_events() {
            // 点击屏幕，if_movable为true，才可以移动所有图片
            // 鼠标按下
            this.canvas.onmousedown = (e) => {
                this.if_movable = true;
                this.mouse.x = e.x;
                this.mouse.y = e.y;
            };
            // 手指点击
            this.canvas.ontouchstart = (e) => {
                this.if_movable = true;
                this.mouse.pro_x = this.mouse.x = e.touches[0].clientX;
                this.mouse.pro_y = this.mouse.y = e.touches[0].clientY;
            };
            // 弹起屏幕，if_movable为flase，不可移动图片
            // 鼠标抬起
            this.canvas.onmouseup = (e) => {
                this.if_movable = false;
                // 如果鼠标点击的时候未发生移动，检查鼠标所点击的图片
                if (this.mouse.x == e.x && this.mouse.y == e.y) this.select_img(e.x, e.y);
            };
            // 手指松开
            this.canvas.ontouchend = (e) => {
                this.if_movable = false;
                // touchend后，手指没有点击数据，只能用上一次移动的xy坐标来判断
                // 并且，因为mouse.x和mouse.y一直在变化，故只能用第一次按下的xy坐标来判断是否移动
                if (this.mouse.pro_x == this.mouse.x && this.mouse.pro_y == this.mouse.y)
                    this.select_img(this.mouse.x, this.mouse.y);
            };
            // 鼠标离开，if_movable为flase，不可移动图片
            this.canvas.onmouseleave = () => {
                this.if_movable = false;
            };
            // 移动屏幕
            // 鼠标移动，if_movable为true则移动所有图片
            this.canvas.onmousemove = (e) => {
                if (!this.if_movable) return;
                this.move_imgs(e.movementX, e.movementY);
            };
            // 手指移动
            this.canvas.ontouchmove = (e) => {
                e.preventDefault(); // 阻止移动端默认事件，防止滑动刷新，以及双指缩放
                if (!this.if_movable) return;
                // 移动端没法用接口直接判断每一次移动的距离，所以只能用每次移动的xy坐标差来计算距离
                this.move_imgs(
                    e.touches[0].clientX - this.mouse.x,
                    e.touches[0].clientY - this.mouse.y
                );
                // 更新xy坐标
                this.mouse.x = e.touches[0].clientX;
                this.mouse.y = e.touches[0].clientY;
            };
        },
        wheel(e) {
            this.move_imgs(0, 10 * Math.sign(e.wheelDeltaY));
        },
        // 移动所有图片
        move_imgs(x, y) {
            // 当checkbox可见的时候，则不可移动绘制图片
            if (this.checkbox.if_visible) return; //这里主要是为了避免在checkbox显示的时候，滚动滚轮，出现图片显示BUG
            // 用于计算缓动
            this.ease.x += x * 0.2;
            this.ease.y += y * 0.2;
            const draw = () => {
                this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.imgs_data.forEach((img) => {
                    img.x += this.ease.x;
                    // 大于总宽最右侧，则回到最左边，这里减去一个宽度是为了防止右移的时候，左边出现空白
                    if (img.x > this.total_width - this.img_width)
                        img.x -= this.total_width + this.img_margin;
                    // 小于最左侧的一个负宽度，则移动到最右边
                    if (img.x < -this.img_width) img.x += this.total_width + this.img_margin;
                    // 竖向同上
                    img.y += this.ease.y;
                    if (img.y > this.total_height - this.img_height)
                        img.y -= this.total_height + this.img_margin;
                    if (img.y < -this.img_height) img.y += this.total_height + this.img_margin;
                    // 如果图片加载完成，则绘制图片,图片未加载完成，避免页面空白，用圆角矩形填充对应位置
                    this.context.drawImage(
                        img.if_loaded ? img.img : this.img_mask,
                        img.x * this.draw_scale,
                        img.y * this.draw_scale,
                        this.img_width * this.draw_scale,
                        this.img_height * this.draw_scale
                    );
                });
                // 计算缓动
                this.ease.x = this.ease.damping(this.ease.x);
                this.ease.y = this.ease.damping(this.ease.y);
                if (Math.abs(this.ease.x) < 0.01 && Math.abs(this.ease.y) < 0.01) {
                    this.ease.x = 0;
                    this.ease.y = 0;
                    this.ease.id = null;
                    return;
                }
                this.ease.id = requestAnimationFrame(draw);
            };
            if (!this.ease.id) this.ease.id = requestAnimationFrame(draw);
        },
        // 选择鼠标所指向的图片
        select_img(x, y) {
            // 选中所有处于视图范围内的图片
            this.imgs_inview = this.imgs_data.filter((img) => {
                return (
                    img.x * this.scale_nums >= -this.img_width * this.scale_nums &&
                    img.x * this.scale_nums <= innerWidth &&
                    img.y * this.scale_nums >= -this.img_height * this.scale_nums &&
                    img.y * this.scale_nums <= innerHeight
                );
            });
            // 找到鼠标所选择的图片，即鼠标的xy坐标在图片内部
            let img = this.imgs_data.find(
                (img) =>
                    x >= img.x * this.scale_nums &&
                    x < img.x * this.scale_nums + this.img_width * this.scale_nums &&
                    y >= img.y * this.scale_nums &&
                    y < img.y * this.scale_nums + this.img_height * this.scale_nums
            );
            // 如果图片存在
            if (img) {
                // 设置被选中的图片不可见，并马上重绘一次，使其隐藏
                this.img_selected = img;
                this.img_selected.if_show = false;
                this.hidden_imgs();
                // 显示checkbox，隐藏所有图片
                this.show_checkbox();
                this.change_imgs(false);
            }
        },
        // 检查是否关闭checkbox
        check_hidden() {
            // 如果处于动画变换中，则不执行
            if (this.checkbox.if_animating) return;
            // 隐藏checkbox,并显示所有图片
            this.hidden_checkbox();
            this.change_imgs(true);
        },
        // 显示checkbox
        show_checkbox() {
            this.checkbox.if_animating = true; //当前checkbox处于动画变换中
            this.checkbox.state = true;
            this.checkbox.if_visible = true;
            this.mousetip.if_shutable = true;
            this.checkbox.img.src = ""; //防止网络不好：导致图片显示延迟
            this.checkbox.img.src = this.img_selected.img.src;
            // 将被选中的图片移动到视图中心
            gsap.fromTo(
                this.checkbox.mover,
                {
                    x: `${this.img_selected.x * this.scale_nums}px`,
                    y: `${this.img_selected.y * this.scale_nums}px`,
                },
                {
                    x: `${(innerWidth - this.img_width * this.scale_nums) / 2}px`,
                    y: `${
                        (innerHeight - this.img_height * this.scale_nums) / 2 - 40 * this.scale_nums
                    }px`,
                    scale: 1.2,
                    duration: 0.8,
                    ease: "power4.out",
                    stagger: { from: "end", each: 0.04 }, //这里的层叠顺序是反的，所以间隔时间需要反过来
                    onComplete: () => {
                        this.checkbox.if_animating = false;
                    },
                }
            );
        },
        // 隐藏checkbox
        hidden_checkbox() {
            this.checkbox.if_animating = true; //当前checkbox处于动画变换中
            this.checkbox.state = false;
            this.mousetip.if_shutable = false;
            // 使图片重新回到原位
            gsap.to(this.checkbox.mover, {
                x: `${this.img_selected.x * this.scale_nums}px`,
                y: `${this.img_selected.y * this.scale_nums}px`,
                scale: 1,
                duration: 0.5,
                ease: "power3.out",
                stagger: { from: "end", each: 0.04 },
                onComplete: () => {
                    this.checkbox.if_animating = false;
                    this.checkbox.if_visible = false; // 动画完成之后，使checkbox不可见
                    // 重绘，最后才显示之前被选择的图片
                    this.img_selected.if_show = true;
                    this.show_imgs();
                },
            });
        },
        // 修改图片，显示或隐藏所有图片，if_show为true代表显示，flase代表隐藏
        change_imgs(if_show) {
            // 检查当前index是否超出imgs_inview长度的函数
            const check_index = () => {
                if (index >= this.imgs_inview.length) {
                    clearInterval(this.change_timer);
                    // 将所有图片的绿色清除
                    this.imgs_inview.forEach((img) => {
                        img.if_green = false;
                    });
                    return true;
                } else return false;
            };
            // 清除动画，确保在图片隐藏的时候，图片是停止的
            cancelAnimationFrame(this.ease.id);
            this.ease.id = null;
            // 设置定时器，每隔一定时间按顺序使视图内的图片隐藏或显示
            let index = 0;
            this.change_timer = setInterval(() => {
                // 如果当前的图片正好是被选中的图片，则index+1，跳过当前序号，
                // 但是要检查跳过之后的序号是否超过imgs_inview的长度，超过则停止函数
                if (this.imgs_inview[index].index == this.img_selected.index) {
                    index++;
                    if (check_index()) return;
                }
                // 使当前序号的图片隐藏或显示
                this.imgs_inview[index].if_show = if_show;
                // 有一定概率，设置接下来的的图片变为绿色填充，制造随机闪烁效果
                if (Math.random() > 0.7) {
                    let green_index =
                        Math.floor(Math.random() * (this.imgs_inview.length - index)) + index;
                    // 这里的判断主要用于图片显示，确保被选中的图片必须最后显示，不能在之前变为绿色
                    if (this.imgs_inview[green_index].index != this.img_selected.index)
                        this.imgs_inview[green_index].if_green = true;
                }
                // 绘制图片
                if (if_show) this.show_imgs();
                else this.hidden_imgs();
                // 检查下一位的图片是否超出imgs_inview长度
                ++index;
                check_index();
            }, 30);
        },
        // 显示窗口范围内的所有图片
        show_imgs() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            // 遍历视图内的所有图片，可以显示则绘制，为绿色块则绘制圆角矩形
            // 注意：显示的顺序是先圆角矩形，再绘制图片
            this.imgs_inview.forEach((img) => {
                if (img.if_green)
                    this.context.drawImage(
                        this.img_mask,
                        img.x * this.draw_scale,
                        img.y * this.draw_scale,
                        this.img_width * this.draw_scale,
                        this.img_height * this.draw_scale
                    );
                if (!img.if_show) return;
                this.context.drawImage(
                    img.img,
                    img.x * this.draw_scale,
                    img.y * this.draw_scale,
                    this.img_width * this.draw_scale,
                    this.img_height * this.draw_scale
                );
            });
        },
        // 隐藏窗口范围内的所有图片
        hidden_imgs() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            // 遍历视图内的所有图片，可以显示则绘制，为绿色块则绘制圆角矩形
            // 注意：隐藏的顺序是先移除图片，再绘制圆角矩形
            this.imgs_inview.forEach((img) => {
                if (!img.if_show) return;
                this.context.drawImage(
                    img.img,
                    img.x * this.draw_scale,
                    img.y * this.draw_scale,
                    this.img_width * this.draw_scale,
                    this.img_height * this.draw_scale
                );
                if (!img.if_green) return;
                this.context.drawImage(
                    this.img_mask,
                    img.x * this.draw_scale,
                    img.y * this.draw_scale,
                    this.img_width * this.draw_scale,
                    this.img_height * this.draw_scale
                );
            });
        },
        // 绑定mousetip的相关事件
        bind_mousetip_events() {
            this.container.onmousemove = this.move_mousetip;
            this.container.onmouseenter = this.show_mousetip;
            this.container.onmouseleave = this.hidden_mousetip;
            this.container.onmouseup = this.reset_mousetip;
        },
        // 鼠标移动，mousetip跟随
        move_mousetip(e) {
            // 移动ircle
            gsap.to(this.mousetip.ele, {
                x: `${e.x}px`,
                y: `${e.y}px`,
                duration: 0.5,
                ease: "power2.out",
            });
            // 当图片可以拖拽移动的时候，mousetip会随着鼠标的移动方向旋转
            if (!this.if_movable) return;
            let rotate_angle;
            let slope = (e.x - innerWidth / 2) / (e.y - innerHeight / 2);
            if (e.y >= innerHeight / 2) rotate_angle = (Math.atan(slope) * 180) / Math.PI + 90;
            else rotate_angle = 360 - 90 + (Math.atan(slope) * 180) / Math.PI;
            // 计算本次和上次的角度间隔，保证旋转角度连续
            let base_angle = this.mousetip.last_angle % 360;
            if (base_angle < 0) base_angle += 360;
            let diff = rotate_angle - base_angle;
            if (diff >= 180) diff -= 360;
            else if (diff < -180) diff += 360;
            rotate_angle = this.mousetip.last_angle + diff;
            gsap.to(this.mousetip.ele, {
                rotate: `${-rotate_angle}deg`,
                duration: 0.5,
                ease: "power2.out",
            });
            this.mousetip.last_angle = rotate_angle;
        },
        // 显示mousetip
        show_mousetip(e) {
            gsap.timeline().to(this.mousetip.ele, {
                x: `${e.x}px`,
                y: `${e.y}px`,
                scale: 1,
                duration: 0.8,
                ease: "power3.out",
            });
        },
        // 隐藏mousetip
        hidden_mousetip() {
            this.reset_mousetip();
            gsap.to(this.mousetip.ele, {
                scale: 0,
                duration: 0.8,
                ease: "power3.out",
            });
        },
        // 重置mousetip的旋转角度
        reset_mousetip() {
            gsap.timeline()
                // 如果不对旋转角度取模的话，在鼠标离开页面，icon角度归零时，会大幅度旋转
                .set(this.mousetip.ele, {
                    rotate: `${this.mousetip.last_angle % 360}deg`,
                })
                .to(this.mousetip.ele, {
                    rotate: "0deg",
                    duration: 0.5,
                    ease: "power2.out",
                });
            this.mousetip.last_angle = 0;
        },
        // 开屏动画
        init_animation() {
            // 让所有图片水平滑动
            this.move_imgs(innerWidth / 4, 0);
        },
    },
    mounted() {
        this.$parent.init_animation = this.init_animation;
        this.init();
    },
    beforeDestroy() {
        this.$parent.init_animation = () => {};
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("wheel", this.wheel);
        clearInterval(this.change_timer);
        cancelAnimationFrame(this.ease.id);
        this.json_photos_data = null;
        this.container = null;
        this.canvas = null;
        this.context = null;
        this.imgs_data = null;
        this.img_mask = null;
        this.img_selected = null;
        this.imgs_inview = null;
        this.change_timer = null;
        this.ease = null;
        this.checkbox = null;
        this.mousetip = null;
    },
};
</script>

<style>
#photos {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    height: 100dvh;
    overflow: hidden;
}

.photos_photobox,
.photos_checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.2);
    transition: transform 0.8s ease 0.1s;
}
.photos_photobox {
    cursor: pointer;
}

.photos_checkbox {
    justify-content: center;
    align-items: center;
    touch-action: none;
}
.photos_photobox_activated,
.photos_checkbox_activated {
    transform: scale(1);
}

.photos_checkbox_mover {
    position: absolute;
    left: 0;
    top: 0;
}
.photos_checkbox_line::after,
.photos_checkbox_line::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-width: calc(var(--scale) * 0.2px);
    border-style: solid;
    border-color: var(--theme_white);
    border-radius: calc(1.6vmin);
}
.photos_checkbox_line_front::after {
    transform: scale(0.92);
    opacity: 0.6;
}
.photos_checkbox_line_after::after {
    transform: scale(0.9);
    opacity: 0.4;
}

.photos_checkbox_img {
    pointer-events: all;
}
.photos_checkbox_content {
    position: absolute;
    flex-direction: column;
    align-items: center;
    margin-top: calc(var(--scale) * 550px);
}
.pcc_name {
    margin-bottom: calc(var(--scale) * 15px);
    margin-right: calc(var(--scale) * -3px);
    transition: transform 0.5s ease;
    transform: translateY(30%);
}
.pcc_name span {
    font-family: eng;
    font-size: calc(var(--scale) * 35px);
    color: var(--theme_green);
    letter-spacing: calc(var(--scale) * 3px);
    text-transform: uppercase;
    transition: opacity 0.2s ease;
    transition-delay: calc(var(--i) * 1s);
    opacity: 0;
}
.pcc_time {
    position: relative;
    font-family: zh;
    font-size: calc(var(--scale) * 18px);
    color: var(--theme_white);
    letter-spacing: calc(var(--scale) * 12px);
    margin-right: calc(var(--scale) * -12px);
    opacity: 0;
    transition: margin-right 0.5s ease, letter-spacing 0.5s ease, opacity 0.5s ease;
}
.pcc_time::after,
.pcc_time::before {
    content: "+";
    position: absolute;
    font-family: zh;
    font-size: calc(var(--scale) * 18px);
    color: var(--theme_white);
    transition: transform 0.5s ease;
}
.pcc_time::after {
    right: 0;
    transform: translateX(calc(var(--scale) * 32px));
}
.pcc_time::before {
    left: 0;
    transform: translateX(calc(var(--scale) * -32px));
}
.photos_checkbox_show .pcc_name {
    transform: translateY(0%);
}
.photos_checkbox_show .pcc_name span {
    opacity: 1;
}
.photos_checkbox_show .pcc_time {
    letter-spacing: calc(var(--scale) * 8px);
    margin-right: calc(var(--scale) * -8px);
    opacity: 1;
}
.photos_checkbox_show .pcc_time::after {
    transform: translateX(calc(var(--scale) * 25px));
}
.photos_checkbox_show .pcc_time::before {
    transform: translateX(calc(var(--scale) * -25px));
}

.photos_draglines {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}
.photos_draglines p {
    font-family: eng;
    font-size: 2.5rem;
    color: var(--theme_black);
}
.photos_draglines svg {
    width: 1.8rem;
    height: 1.8rem;
}
.photos_draglines svg polyline,
.photos_draglines svg line {
    fill: none;
    stroke: var(--theme_black);
    stroke-width: 7;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.photos_draglines_up,
.photos_draglines_down {
    position: absolute;
    width: 100%;
    height: 3.5rem;
    overflow: hidden;
}
.photos_draglines_up {
    justify-content: start;
    top: 0;
    right: 3.5rem;
    transition: transform 0.8s ease 0.1s, right 0.8s ease 0.1s;
}
.photos_draglines_down {
    justify-content: end;
    bottom: 0;
    left: 3.5rem;
    transition: transform 0.8s ease 0.1s, left 0.8s ease 0.1s;
}
.photos_draglines_up > div,
.photos_draglines_down > div {
    align-items: center;
    height: 100%;
    background-color: var(--theme_green);
}
.photos_draglines_up > div div,
.photos_draglines_down > div div {
    align-items: center;
}
.photos_draglines_up > div {
    justify-content: start;
    animation: draglines_up 8s linear infinite;
}
.photos_draglines_up p {
    margin-right: 1rem;
}
.photos_draglines_up svg {
    margin-right: 3rem;
    transform: rotate(180deg);
}
@keyframes draglines_up {
    100% {
        transform: translateX(-100%);
    }
}
.photos_draglines_down > div {
    justify-content: end;
    animation: draglines_down 8s linear infinite;
}
.photos_draglines_down p {
    margin-left: 1rem;
}
.photos_draglines_down svg {
    margin-left: 3rem;
}

@keyframes draglines_down {
    100% {
        transform: translateX(100%);
    }
}
.photos_draglines_left,
.photos_draglines_right {
    position: absolute;
    flex-direction: column;
    width: 3.5rem;
    height: 100%;
    overflow: hidden;
}
.photos_draglines_left {
    justify-content: start;
    top: 3.5rem;
    left: 0;
    transition: transform 0.8s ease 0.1s, top 0.8s ease 0.1s;
}
.photos_draglines_right {
    justify-content: end;
    bottom: 3.5rem;
    right: 0;
    transition: transform 0.8s ease 0.1s, bottom 0.8s ease 0.1s;
}
.photos_draglines_left > div,
.photos_draglines_right > div {
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--theme_green);
}
.photos_draglines_left > div div,
.photos_draglines_right > div div {
    flex-direction: column;
    align-items: center;
}
.photos_draglines_left > div {
    justify-content: start;
    animation: draglines_left 8s linear infinite;
}
.photos_draglines_left p {
    writing-mode: vertical-lr;
    margin-bottom: 1rem;
}
.photos_draglines_left svg {
    margin-bottom: 3rem;
    transform: rotate(-90deg);
}
@keyframes draglines_left {
    100% {
        transform: translateY(-100%);
    }
}
.photos_draglines_right > div {
    justify-content: end;
    animation: draglines_right 8s linear infinite;
}
.photos_draglines_right p {
    writing-mode: vertical-lr;
    margin-top: 1rem;
}
.photos_draglines_right svg {
    margin-top: 3rem;
    transform: rotate(90deg);
}

@keyframes draglines_right {
    100% {
        transform: translateY(100%);
    }
}
.photos_draglines_hidden .photos_draglines_up {
    transform: translateY(-100%);
    right: 0;
}
.photos_draglines_hidden .photos_draglines_down {
    transform: translateY(100%);
    left: 0;
}
.photos_draglines_hidden .photos_draglines_left {
    transform: translateX(-100%);
    top: 0;
}
.photos_draglines_hidden .photos_draglines_right {
    transform: translateX(100%);
    bottom: 0;
}
.photos_draglines_hidden .photos_draglines_up > div,
.photos_draglines_hidden .photos_draglines_down > div,
.photos_draglines_hidden .photos_draglines_left > div,
.photos_draglines_hidden .photos_draglines_right > div {
    animation-play-state: paused;
}

.photos_mousetip {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 6rem;
    height: 6rem;
    background-color: var(--theme_green);
    border-radius: 50%;
    margin-left: -3rem;
    margin-top: -3rem;
    transform: scale(0) rotate(-180deg);
    pointer-events: none;
    z-index: 10000;
}

.photos_mousetip_icon {
    position: absolute;
    width: 100%;
    height: 100%;
    fill: none;
    stroke: var(--theme_black);
    stroke-width: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: transform 0.5s ease;
}
.photos_mousetip_icon line,
.photos_mousetip_icon polyline {
    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease, stroke-width 0.5s ease;
}
.photos_mousetip_icon line:nth-child(1) {
    stroke-dasharray: 0 150 0;
    stroke-dashoffset: 10;
}
.photos_mousetip_icon line:nth-child(2) {
    stroke-dasharray: 45 40 55;
}
.photos_mousetip_icon polyline {
    stroke-dasharray: 0 0 70 0 0;
    stroke-dashoffset: 10;
}
.photos_mousetip_shut .photos_mousetip_icon {
    transform: scale(0.6);
}
.photos_mousetip_shut .photos_mousetip_icon line:nth-child(1) {
    stroke-width: 20;
    stroke-dasharray: 70 0 70;
    stroke-dashoffset: 0;
}
.photos_mousetip_shut .photos_mousetip_icon line:nth-child(2) {
    stroke-width: 20;
    stroke-dasharray: 70 0 70;
}
.photos_mousetip_shut polyline {
    stroke-dasharray: 0 40 0 40 0;
    stroke-dashoffset: 10;
}
</style>
