<template>
    <div id="error">
        <svg class="error_background" viewBox="0 0 1920 1920">
            <image :href="require('../assets/images/404.svg')" width="1920" height="1920" />
            <g @click="$router.push('home')">
                <path
                    d="M1289.7,1103.1H1151.1a10.5,10.5,0,0,1-10.5-10.5v-30.1a10.5,
                10.5,0,0,1,10.5-10.5h138.6a10.5,10.5,0,0,1,10.5,10.5v30.1A10.5,10.5,0,0,1,1289.7,1103.1Z"
                />
                <text transform="translate(1185.47 1088.54)">HOME</text>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$parent.init_animation = () => {};
    },
};
</script>

<style>
#error {
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--theme_black);
    overflow: hidden;
}
.error_background {
    position: absolute;
    width: 100%;
    height: auto;
}
.error_background g {
    cursor: pointer;
}
.error_background path {
    fill: none;
    stroke: var(--theme_white);
    stroke-width: 2px;
}
.error_background text {
    font-family: eng;
    font-size: 27.7px;
    fill: var(--theme_white);
    letter-spacing: 0.1rem;
    transition: fill 0.5s ease;
}

@media (hover: hover) {
    .error_background g:hover text {
        fill: var(--theme_green);
    }
}

@media screen and (max-aspect-ratio: 1/1) {
    .error_background {
        width: auto;
        height: 100%;
    }
}
</style>